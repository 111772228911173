import React from 'react'
import './PageNotVisibleAllHotels.css'

const PageNotVisibleAllHotels = () => {
  return (
    <div className='dataZone'>
      <div className='pageContainer'>
        <h1>VISIÓN NO POSIBLE CON TODOS LOS HOTELES</h1>
        <h1>O SIN DATOS QUE MOSTRAR</h1>
        <h1>SELECCIONA UN SOLO HOTEL</h1>
        
      </div>
    </div>
  )
}

export default PageNotVisibleAllHotels

