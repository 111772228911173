import React, { useEffect, useState } from "react";
import "./AllProducts.css";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const AllProducts = ({ setAllProductsVisible, data, title }) => {
  const [total, setTotal] = useState(null);

  useEffect(() => {
    const totales = data.reduce((acc, item) => {
      return acc + item.income;
    }, 0);
    

    const unidades = data.reduce((acc, item) => {
      return acc + item.totalUnits;
    }, 0);

    setTotal({income: totales, unidades: unidades});

  }, [data]);
  return (
    <div className="allProductsContainer">
      <div className="allProductsTitle">
        {title && <h1>{`Ingreso por producto ${title}`}</h1>}
        <button
          onClick={() => setAllProductsVisible(false)}
          className="btnCloseAllProducts"
        >
          Cerrar
        </button>
      </div>

      <div className="allProductsListContainer">
      <div className="allProductsListItem">
              <p className="allProductsListName" id="alProductsListIncomeTotal">TOTALES</p>
              {total && <p className="allProductsListIncome" id="alProductsListIncomeTotal">
                {formatNumber(total.income)} €
              </p>}
              {total && <p className="allProductsListIncome" id="alProductsListIncomeTotal">
                {formatNumber(total.unidades)} Uds
              </p>}
            </div>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div key={index} className="allProductsListItem">
              <p className="allProductsListName">{item.productName}</p>
              <p className="allProductsListIncome">
                {formatNumber(item.income)} €
              </p>
              <p className="allProductsListIncome">
                {formatNumber(item.totalUnits)} Uds
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AllProducts;
