import api from "./api"

const dataPost = {
    postCurrency: async(data) => {
        return new Promise((resolve, reject) => {
            api.post("/currency/create", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    group: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/hotel_group/create', data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    hotel: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/hotel_group/create', data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    hotelContact: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/hotel_contacts/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    groupContact: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/group_contacts/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    hotelStaff: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/staff/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    hotelObjective: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/hotel_objectives/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    hotelRoom: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/rooms/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    hotelHistory: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/historical/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    
    upsellingItem: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/hotel_rooms_upselling/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    crossellingItem: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/crosselling_items/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    crossellingConcept: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/crosselling_concepts/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    dailyEntry: async(data) => {
        return new Promise((resolve, reject) => {
            api.post('/daily_entry/create', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getDailyReport: async(data) => {
        try {
            const response = await api.getReport('/reports/daily', data);
            return response;
        } catch (error) {
            console.error("Error al generar el informe:", error);
            throw new Error("Error al generar el informe");
        }
    },
    
    getPeriodeReport: async(data) => {
        return new Promise((resolve, reject) => {
            api.getReport('/reports/periode', data)
            .then((response)=>{
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}

export default dataPost;