// BarChart.jsx
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registramos los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RankingHotelsSellPoints = ({ data }) => {
  // Extraemos los nombres y los ingresos totales de los puntos de venta
  const labels = data.map((item) => item.sellPointName);
  const totalIncomes = data.map((item) => item.totalIncome);

  // Configuración de los datos para el gráfico
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Income",
        data: totalIncomes,
        backgroundColor: "rgba(75, 192, 192, 1)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Opciones de configuración del gráfico
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Desactiva la leyenda
      },
      title: {
        display: false,
        text: "Total Income by Sell Point",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Punto de venta",
        },
      },
      y: {
        title: {
          display: true,
          text: "Ingreso",
        },
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options}/>;
};

export default RankingHotelsSellPoints;
