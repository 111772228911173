import React from "react";
import "./Nodata.css";

const NoData = () => {
  return (
    <div className="dataZone">
      <div className="noData">
        <h1>SELECCIONA </h1>
        <h1>HOTEL DEPARTAMENTO Y PERIODICIDAD</h1>
      </div>
    </div>
  );
};

export default NoData;
