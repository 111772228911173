import React from 'react'
import './NoContracted.css'
import logoHbd from '../../../Images/logoHbd.svg'

const NoContracted = () => {

    return (
        <div className='dataZone'>
            <div className='notContractedContainer'>
               
                <h1>DEPARTAMENTO NO CONTRATADO</h1>
                <h1>CONTACTE CON HBD</h1>
                <div className='notContractedLogo'>
                    <a href="https://www.upsellingsolutions.com" target="_blank" rel="noopener noreferrer">
                        <img src={logoHbd} alt='logo' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NoContracted
