import React, { useEffect, useState } from "react";
import "./SellPointsView.css";

import dataGet from "../../../Shared/API/dataGet";
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import TopLastGraphic from "../../../Components/Graphics/TopLastGraphic";
import { useAppContext } from "../../../Shared/Context/AppContext";
import PageNotVisibleAllHotels from "../General/PageNotVisibleAllHotels";
import AllProducts from "./AllProducts";

const formatDate = (date, periodicity) => {
  const dateParts = date.split("-");
  const dateDay = dateParts[2];
  const dateMonth = dateParts[1];
  const dateYear = dateParts[0];
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  if (periodicity) {
    if (parseInt(periodicity) === 1) {
      return `${dateDay}-${dateMonth}-${dateYear}`;
    } else if (parseInt(periodicity) === 10) {
      return `${meses[parseInt(dateMonth) - 1]} de ${dateYear}`;
    } else {
      return `${dateYear}`;
    }
  }
};

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const SellPointsView = ({ hotelSelected }) => {
  const [showNotPossible, setShowNotPosible] = useState(false);

  const { hotelData, periodicity } = useAppContext();
  const graphArr = Array.from({ length: 100 }, (_, index) => index + 1);
  const [data, setData] = useState(null);
  const [totalIncome, setTotalIncome] = useState(null);
  const [totalIncomeAnteriorPeriode, setTotalIncomeAnteriorPeriode] =
    useState(null);
  const [totalIncomeDif, setTotalIncomeDif] = useState({
    value: 0,
    percent: 0,
  });
  const [totalUnits, setTotalUnits] = useState(null);
  const [totalUnitsAnteriorPeridode, setTotalUnitsAnteriorPeriode] =
    useState(null);
  const [totalUnitsDif, setTotalUnitsDif] = useState({ value: 0, percent: 0 });
  const [incomePerStaff, setIncomePerStaff] = useState(null);
  const [anteriorIncomePerStaff, setAnteriorIncomePerStaff] = useState(null);
  const [incomePerStaffDif, setIncomePerStaffDif] = useState({
    value: 0,
    percent: 0,
  });
  const [ticketAverage, setTicketAverage] = useState(null);
  const [ticketAveragePeriodeAnterior, setTicketAveragePeriodeAnterior] =
    useState(null);
  const [ticketAverageDif, setTicketAverageDif] = useState({
    value: 0,
    percent: 0,
  });
  const [incomePerStance, setIncomePerStance] = useState(null);
  const [incomePerStanceAnteriorPeriode, setIncomePerStanceAnteriorPeriode] =
    useState(null);
  const [incomePerStanceDif, setIncomePerStanceDif] = useState({
    value: 0,
    percent: 0,
  });
  const [hotelFbItemAverage, setHotelFbItemAverage] = useState(null);
  const [fixedFbItemAverage, setFixedFbItemAverage] = useState(null);
  const [hotelFbItemAverageDif, setHotelFbItemAverageDif] = useState({
    value: 0,
    percent: 0,
  });

  const [objective, setObjective] = useState([]);
  const [totalIncomeForSellPoint, setTotalIncomeForSellPoint] = useState([]);
  const [sellPointsGraphDataArr, setSellPointsGraphDataArr] = useState([]);
  const [topLastProducs, setTopLastProducs] = useState(null);
  const [sellPointSelected, setSellPointSelected] = useState(null);
  const [existData, setExistData] = useState(false);
  const [allProductsVisible, setAllProductsVisible] = useState(false);

  useEffect(() => {
    if (hotelSelected === "1") {
      setShowNotPosible(true);
    } else {
      setShowNotPosible(false);
    }
    const date = new Date();
    date.setDate(date.getDate() - 1);
    setSellPointSelected(undefined);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const yesterdayDate = `${year}-${month}-${day}`;
    const dates = `${yesterdayDate}, ${yesterdayDate}`;

    dataGet
      .getFBData(dates, hotelSelected, false, false)
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });
  }, [hotelSelected]);

  useEffect(() => {
    setFixedFbItemAverage(hotelData.fbItemsAverage);
    let incomeTotal = 0;
    let preIncome = 0;
    let difIncome = 0;
    let difIncomePercent = 0;

    let unitsTotal = 0;
    let preUnits = 0;
    let difUnits = 0;
    let difUnitsPercent = 0;

    let stancesTotal = 0;
    let preStances = 0;
    let difStances = 0;
    let difStancesPercent = 0;

    let periodeSellPoints = [];
    let preSellPoints = [];

    let periodeStaff = 0;
    let preStaff = 0;

    let incomeStaffTotal = 0;
    let preIncomeStaffTotal = 0;
    let incomeEstaffDif = 0;
    let incomeStaffDifPercent = 0;

    let actualTicketAverage = 0;
    let preTicketAverage = 0;
    let actualTicketAverageDif = 0;
    let actualTicketAveragePercent = 0;

    let actualIncomePerStance = 0;
    let preIncomePerStance = 0;
    let actualIncomePerStanceDif = 0;
    let actualIncomePerStancePercent = 0;
    let totalIncomeForSellPointArr;
    let objectiveArr;
    let topLastProductsArr;
    let productAverage = 0;
    let productAverageDif = 0;
    let productAveragePercent = 0;

    if (data && data.data && periodicity) {
      if (parseInt(periodicity) === 1) {
        if (data.data.MTD.length > 0) {
          setExistData(true);
        } else {
          setExistData(false);
        }
        incomeTotal = getTotalIncome(data.data.periode, sellPointSelected);
        preIncome = getTotalIncome(data.data.Yesterday, sellPointSelected);
        difIncome = incomeTotal - preIncome;
        difIncomePercent =
          preIncome !== 0 ? ((incomeTotal * 100) / preIncome).toFixed() : 100;

        periodeSellPoints = getActiveSellPoints(data.data.periode);
        preSellPoints = getActiveSellPoints(data.data.Yesterday);
        periodeStaff = getStaff(periodeSellPoints, data.sellPoints);
        preStaff = getStaff(preSellPoints, data.sellPoints);

        unitsTotal = getTotalUnits(data.data.periode, sellPointSelected);
        preUnits = getTotalUnits(data.data.Yesterday, sellPointSelected);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent =
          preUnits !== 0 ? ((unitsTotal * 100) / preUnits).toFixed() : 100;

        stancesTotal = data.dailyInfoRequired.periode.stances;
        preStances = data.dailyInfoRequired.yesterday.stances;
        difStances = stancesTotal - preStances;
        difStancesPercent =
          preStances !== 0
            ? ((stancesTotal * 100) / preStances).toFixed()
            : 100;

        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preIncome / preStaff;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;

        actualTicketAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
        preTicketAverage = preUnits !== 0 ? preIncome / preUnits : 0;

        actualTicketAverageDif = actualTicketAverage - preTicketAverage;
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;

        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preIncome / preStances;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 100
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = getTotalIncomeForSellPoint(data.data.MTD);
        objectiveArr = data.objectives.monthObjectives;
        topLastProductsArr = getTopLastProducts(
          data.data.periode,
          sellPointSelected
        );
      } else if (parseInt(periodicity) === 10) {
        if (data.data.MTD.length > 0) {
          setExistData(true);
        } else {
          setExistData(false);
        }
        incomeTotal = getTotalIncome(data.data.MTD, sellPointSelected);
        preIncome = getTotalIncome(data.data.lastMonth, sellPointSelected);
        difIncome = incomeTotal - preIncome;
        difIncomePercent =
          preIncome !== 0 ? (incomeTotal * 100) / preIncome : 100;
        unitsTotal = getTotalUnits(data.data.MTD, sellPointSelected);
        preUnits = getTotalUnits(data.data.lastMonth, sellPointSelected);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;
        stancesTotal = data.dailyInfoRequired.MTD.stances;
        preStances = data.dailyInfoRequired.lastMonth.stances;
        difStances = stancesTotal - preStances;
        difStancesPercent =
          preStances !== 0
            ? ((stancesTotal * 100) / preStances).toFixed()
            : 100;
        periodeSellPoints = getActiveSellPoints(data.data.MTD);
        preSellPoints = getActiveSellPoints(data.data.lastMonth);
        periodeStaff = getStaff(periodeSellPoints, data.sellPoints);
        preStaff = getStaff(preSellPoints, data.sellPoints);

        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preIncome / preStaff;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;

        actualTicketAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
        preTicketAverage =
          preUnits !== 0 ? parseFloat(preIncome) / parseFloat(preUnits) : 0;
        actualTicketAverageDif =
          parseFloat(actualTicketAverage) - parseFloat(preTicketAverage);
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = getTotalIncomeForSellPoint(data.data.MTD);
        objectiveArr = data.objectives.monthObjectives;
        topLastProductsArr = getTopLastProducts(
          data.data.MTD,
          sellPointSelected
        );
      } else if (parseInt(periodicity) === 5) {
        if (data.data.WTD.length > 0) {
          setExistData(true);
        } else {
          setExistData(false);
        }
        incomeTotal = getTotalIncome(data.data.WTD, sellPointSelected);
        preIncome = getTotalIncome(data.data.lastWeek, sellPointSelected);
        difIncome = incomeTotal - preIncome;
        difIncomePercent =
          preIncome !== 0 ? (incomeTotal * 100) / preIncome : 100;
        unitsTotal = getTotalUnits(data.data.WTD, sellPointSelected);
        preUnits = getTotalUnits(data.data.lastWeek, sellPointSelected);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;
        stancesTotal = data.dailyInfoRequired.WTD.stances;
        preStances = data.dailyInfoRequired.lastWeek.stances;
        difStances = stancesTotal - preStances;
        difStancesPercent =
          preStances !== 0
            ? ((stancesTotal * 100) / preStances).toFixed()
            : 100;
        periodeSellPoints = getActiveSellPoints(data.data.WTD);
        preSellPoints = getActiveSellPoints(data.data.lastWeek);
        periodeStaff = getStaff(periodeSellPoints, data.sellPoints);
        preStaff = getStaff(preSellPoints, data.sellPoints);

        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preIncome / preStaff;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;

        actualTicketAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
        preTicketAverage =
          preUnits !== 0 ? parseFloat(preIncome) / parseFloat(preUnits) : 0;
        actualTicketAverageDif =
          parseFloat(actualTicketAverage) - parseFloat(preTicketAverage);
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = getTotalIncomeForSellPoint(data.data.WTD);
        objectiveArr = data.objectives.monthObjectives;
        topLastProductsArr = getTopLastProducts(
          data.data.WTD,
          sellPointSelected
        );
      } else if (parseInt(periodicity) === 100) {
        if (data.data.MTD.length > 0) {
          setExistData(true);
        } else {
          setExistData(false);
        }
        incomeTotal = getTotalIncome(data.data.YTD, sellPointSelected);
        preIncome = getTotalIncome(data.data.lastYear, sellPointSelected);
        difIncome = totalIncome - preIncome;
        difIncomePercent =
          preIncome !== 0 ? (totalIncome * 100) / preIncome : 100;
        unitsTotal = getTotalUnits(data.data.YTD, sellPointSelected);
        preUnits = getTotalUnits(data.data.lastYear, sellPointSelected);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;
        stancesTotal = data.dailyInfoRequired.YTD.stances;
        preStances = data.dailyInfoRequired.lastYear.stances;
        difStances = (stancesTotal - preStances).toFixed;
        difStancesPercent =
          preStances !== 0 ? (stancesTotal * 100) / preStances : 100;
        periodeSellPoints = getActiveSellPoints(data.data.YTD);
        preSellPoints = getActiveSellPoints(data.data.lastYear);
        periodeStaff = getStaff(periodeSellPoints, data.sellPoints);
        preStaff = getStaff(preSellPoints, data.sellPoints);
        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preStaff !== 0 ? preIncome / preStaff : 0;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;
        actualTicketAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
        preTicketAverage = preUnits !== 0 ? preIncome / preUnits : 0;
        actualTicketAverageDif = actualTicketAverage - preTicketAverage;
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = getTotalIncomeForSellPoint(data.data.YTD);
        objectiveArr = data.objectives.yearObjectives;
        topLastProductsArr = getTopLastProducts(
          data.data.YTD,
          sellPointSelected
        );
      }
    }
    productAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
    productAverageDif = hotelFbItemAverage - fixedFbItemAverage;
    productAveragePercent =
      hotelFbItemAverage !== 0
        ? (fixedFbItemAverage * 100) / hotelFbItemAverage
        : 0;
    setHotelFbItemAverage(productAverage);
    setHotelFbItemAverageDif({
      value: productAverageDif,
      percent: productAveragePercent,
    });

    setTopLastProducs(topLastProductsArr);
    setObjective(objectiveArr);
    setTotalIncomeForSellPoint(totalIncomeForSellPointArr);
    setTotalIncome(incomeTotal);
    setTotalIncomeAnteriorPeriode(preIncome);
    setTotalIncomeDif({ value: difIncome, percent: difIncomePercent });
    setTotalUnits(unitsTotal);
    setTotalUnitsAnteriorPeriode(preUnits);
    setTotalUnitsDif({ value: difUnits, percent: difUnitsPercent });
    setIncomePerStaff(incomeStaffTotal);
    setAnteriorIncomePerStaff(preIncomeStaffTotal);
    setIncomePerStaffDif({
      value: incomeEstaffDif,
      percent: incomeStaffDifPercent,
    });
    setTicketAverage(actualTicketAverage);
    setTicketAveragePeriodeAnterior(preTicketAverage);
    setTicketAverageDif({
      value: actualTicketAverageDif,
      percent: actualTicketAveragePercent,
    });
    setIncomePerStance(actualIncomePerStance);
    setIncomePerStanceAnteriorPeriode(preIncomePerStance);
    setIncomePerStanceDif({
      value: actualIncomePerStanceDif,
      percent: actualIncomePerStancePercent,
    });
  }, [
    data,
    periodicity,
    sellPointSelected,
    fixedFbItemAverage,
    hotelFbItemAverage,
    totalIncome,
  ]);

  const getTotalIncomeForSellPoint = (data) => {
    const resultArr = [];
    for (const sellPoint of data) {
      const totalIncome = sellPoint.products.reduce((acc, item) => {
        return acc + item.totalPrice * item.totalUnits;
      }, 0);

      const objToPush = {
        sellPoint: sellPoint.pointOfSaleName,
        income: totalIncome,
      };

      resultArr.push(objToPush);
    }

    return resultArr;
  };
  const getTotalIncome = (data, sellPoint) => {
    if (sellPoint) {
      const dataSellPoint = data.find(
        (item) => item.pointOfSaleName === sellPoint
      );
      if (dataSellPoint) {
        const productsList = dataSellPoint.products;
        const totalProductIncome = productsList.reduce((acc, item) => {
          return acc + item.totalPrice * item.totalUnits;
        }, 0);
        return totalProductIncome;
      } else {
        return 0;
      }
    }
  };

  const getTotalUnits = (data, sellPoint) => {
    if (sellPoint) {
      const dataSellPoint = data.find(
        (item) => item.pointOfSaleName === sellPoint
      );
      if (dataSellPoint) {
        const productsList = dataSellPoint.products;
        const totalProductsUnits = productsList.reduce((acc, item) => {
          return acc + item.totalUnits;
        }, 0);
        return totalProductsUnits;
      } else {
        return 0;
      }
    }
  };

  const getTopLastProducts = (data, sellPoint) => {
    if (sellPoint) {
      const dataSellPoint = data.find(
        (item) => item.pointOfSaleName === sellPoint
      );

      if (dataSellPoint) {
        const totalProductList = dataSellPoint.products;
      
        // Inicializa el acumulador para totalUnits
        let totalUnitsSum = 0;
      
        const groupedProducts = totalProductList.reduce((acc, product) => {
          const { productName, totalPrice, totalUnits } = product;
          const income = totalPrice * totalUnits;
      
          // Suma las unidades al total general
          totalUnitsSum += totalUnits;
      
          if (!acc[productName]) {
            acc[productName] = {
              productName,
              income: 0,
              totalUnits: 0, // También acumulará las unidades por producto
            };
          }
      
          // Acumula los ingresos y las unidades por producto
          acc[productName].income += income;
          acc[productName].totalUnits += totalUnits;
      
          return acc;
        }, {});
      
        const resultArray = Object.values(groupedProducts);
        resultArray.sort((a, b) => b.income - a.income);
      
        const top = resultArray.slice(0, 5);
        const last = resultArray.slice(-5);
        last.reverse();
      
        // Devuelve también el sumatorio de totalUnits
        return { 
          top: top, 
          last: last, 
          all: resultArray, 
          totalUnitsSum: totalUnitsSum // Suma total de las unidades 
        };
      }
      
    }
  };

  const getActiveSellPoints = (data) => {
    return data.map((item) => item.pointOfSale);
  };
  const getStaff = (activeSellPoints, sellPoints) => {
    const filteredSellPoints = sellPoints.filter((item) =>
      activeSellPoints.includes(item.sellPointCode)
    );
    const response = filteredSellPoints.reduce((acc, item) => {
      return acc + item.numberStaff;
    }, 0);
    return response;
  };

  useEffect(() => {
    const dataArray = [];
    if (
      totalIncomeForSellPoint &&
      totalIncomeForSellPoint.length > 0 &&
      objective.length > 0
    ) {
      for (const sellPoint of totalIncomeForSellPoint) {
        const sellPointName = sellPoint.sellPoint; // Nombre del punto de venta
        const sellPointIncome = sellPoint.income; // Ingresos del punto de venta
        const sellPointObjective = objective.find(
          (item) => item.sellPointName === sellPoint.sellPoint
        )?.objective; // Objetivo del punto de venta

        // Si hay un objetivo definido para el punto de venta

        if (sellPointObjective !== undefined) {
          const objToPush = {
            sellPoint: sellPointName,
            income: sellPointIncome,
            objective: sellPointObjective,
            objectivePercent: (sellPointIncome * 100) / sellPointObjective, // Calcula el porcentaje del objetivo
          };
          dataArray.push(objToPush); // Agrega el objeto al array de datos
        }
      }
      setSellPointsGraphDataArr(dataArray);
    }
  }, [totalIncomeForSellPoint, objective]);

  console.log(topLastProducs)
  return (
    <div className="dataZone">
      {!showNotPossible && existData ? (
        <div className="dataZoneContainer">
          <select
            onChange={(e) => {
              const selectedValue =
                e.target.value === "undefined" ? undefined : e.target.value;
              setSellPointSelected(selectedValue);
            }}
            className="sellPointSelector"
          >
            <option value="undefined">PUNTO DE VENTA</option>
            {sellPointsGraphDataArr.map((item, index) => (
              <option key={index} value={item.sellPoint}>
                {item.sellPoint}
              </option>
            ))}
          </select>

          {sellPointSelected && (
            <div>
              <div className="firstLine">
                <div className="dataZoneRowGraph">
                  {parseFloat(totalIncomeDif.value) !== undefined &&
                    parseFloat(totalIncomeDif.percent) !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>Ingresos totales</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {formatNumber(totalIncome)}€
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {parseInt(totalIncome) >
                            parseInt(totalIncomeAnteriorPeriode) ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(totalIncome) >
                                  parseInt(totalIncomeAnteriorPeriode) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {formatNumber(totalIncomeDif.value)}€
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(totalIncomeDif.percent)}%
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {formatNumber(totalIncomeDif.value)}€
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(totalIncomeDif.percent)}%
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                {parseFloat(totalIncomeDif.value) &&
                                  parseFloat(totalIncomeDif.percent) && (
                                    <div className="dataZoneCardItemText">
                                      {parseInt(totalIncome) >
                                      parseInt(totalIncomeAnteriorPeriode) ? (
                                        <div>
                                          <p className="dataZoneCardItemA green">
                                            {formatNumber(totalIncomeDif.value)}
                                            €
                                          </p>

                                          <p className="dataZoneCardItemB green">
                                            {formatNumber(
                                              parseFloat(totalIncomeDif.percent)
                                            )}
                                            %
                                          </p>
                                        </div>
                                      ) : (
                                        <div>
                                          <p className="dataZoneCardItemA red">
                                            {formatNumber(totalIncomeDif.value)}
                                            €
                                          </p>
                                          <p className="dataZoneCardItemB red">
                                            {formatNumber(
                                              parseFloat(totalIncomeDif.percent)
                                            )}
                                            %
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          ventas periodo anterior:{" "}
                          {formatNumber(totalIncomeAnteriorPeriode)}€
                        </p>
                      </div>
                    )}

                  {totalUnitsDif.value !== undefined &&
                    totalUnitsDif.percent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>Unidades vendidas</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {formatNumber(totalUnits)}Uds.
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {totalUnits > totalUnitsAnteriorPeridode ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>

                                <div className="dataZoneCardItemText">
                                  {parseInt(totalUnits) >
                                  parseInt(totalUnitsAnteriorPeridode) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {totalUnitsDif.value}Uds
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(totalUnitsDif.percent)}%
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {totalUnitsDif.value}Uds
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(totalUnitsDif.percent)}%
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(totalUnits) >
                                  parseInt(totalUnitsAnteriorPeridode) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {formatNumber(totalUnitsDif.value)}
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(totalUnitsDif.percent)}%
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {formatNumber(totalUnitsDif.value)}Uds
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(totalUnitsDif.percent)}%
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          uds. vendidas periodo anterior:{" "}
                          {formatNumber(totalUnitsAnteriorPeridode)}Uds
                        </p>
                      </div>
                    )}

                  {incomePerStaffDif.value !== undefined &&
                    incomePerStaffDif.percent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>Ventas por empleado</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {incomePerStaff && formatNumber(incomePerStaff)}€
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {incomePerStaff > anteriorIncomePerStaff ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(incomePerStaff) >
                                  parseInt(anteriorIncomePerStaff) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {incomePerStaffDif.value &&
                                          formatNumber(incomePerStaffDif.value)}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {incomePerStaffDif.percent &&
                                          formatNumber(
                                            incomePerStaffDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {incomePerStaffDif.value &&
                                          formatNumber(incomePerStaffDif.value)}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {incomePerStaffDif.percent &&
                                          formatNumber(
                                            incomePerStaffDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {incomePerStaff &&
                                  parseInt(incomePerStaff) >
                                    formatNumber(anteriorIncomePerStaff) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {incomePerStaffDif.value &&
                                          formatNumber(incomePerStaffDif.value)}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {incomePerStaffDif.percent &&
                                          formatNumber(
                                            incomePerStaffDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {incomePerStaffDif.value &&
                                          formatNumber(incomePerStaffDif.value)}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {incomePerStaffDif.percent &&
                                          formatNumber(
                                            incomePerStaffDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          ventas/empleado periodo anterior:{" "}
                          {anteriorIncomePerStaff &&
                            formatNumber(anteriorIncomePerStaff)}
                          €
                        </p>
                      </div>
                    )}
                </div>
              </div>

              <div className="firstLine">
                <div className="dataZoneRowGraph">
                  {ticketAverageDif.value !== undefined &&
                    ticketAverageDif.percent !== undefined && (
                      <div className="dataZoneCard">
                        <div className="dataZoneCardTitle">
                          <p>Ticket medio</p>
                        </div>
                        <div className="dataZoneCardItemGroup">
                          <div className="dataZoneCardItem">
                            <p className="dataZoneCardItemD">
                              {ticketAverage && formatNumber(ticketAverage)}€
                            </p>
                          </div>
                          <div className="dataZoneCardItem">
                            {ticketAverage > ticketAveragePeriodeAnterior ? (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImage"
                                    src={flechaVerde}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {parseInt(ticketAverage) >
                                  parseInt(ticketAveragePeriodeAnterior) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {ticketAverageDif.value &&
                                          formatNumber(ticketAverageDif.value)}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {ticketAverageDif.percent &&
                                          formatNumber(
                                            ticketAverageDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {ticketAverageDif.value &&
                                          formatNumber(ticketAverageDif.value)}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {ticketAverageDif.percent &&
                                          formatNumber(
                                            ticketAverageDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="dataZoneCardItemEvolutionContainer">
                                <div className="dataZoneCardItemEvolutionItem">
                                  <img
                                    className="dataZoneCardItemEvolutionImageBad"
                                    src={flechaRoja}
                                    alt="arriba"
                                  />
                                </div>
                                <div className="dataZoneCardItemText">
                                  {ticketAverage &&
                                  parseInt(ticketAverage) >
                                    parseInt(ticketAveragePeriodeAnterior) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {ticketAverageDif.value &&
                                          formatNumber(ticketAverageDif.value)}
                                        €
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {ticketAverageDif.percent &&
                                          formatNumber(
                                            ticketAverageDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {ticketAverageDif.value &&
                                          formatNumber(ticketAverageDif.value)}
                                        €
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {ticketAverageDif.percent &&
                                          formatNumber(
                                            ticketAverageDif.percent
                                          )}
                                        %
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="dataZoneCardItemC">
                          ticket medio periodo anterior:{" "}
                          {ticketAveragePeriodeAnterior &&
                            formatNumber(ticketAveragePeriodeAnterior)}
                          €
                        </p>
                      </div>
                    )}

                  {incomePerStanceDif.value && incomePerStanceDif.percent && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>Ingreso por estancia</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {incomePerStance && formatNumber(incomePerStance)}€
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {parseFloat(incomePerStance) >
                          parseFloat(incomePerStanceAnteriorPeriode) ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseFloat(incomePerStance) >
                                parseFloat(incomePerStanceAnteriorPeriode) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {incomePerStanceDif.value &&
                                        formatNumber(incomePerStanceDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {incomePerStanceDif.percent &&
                                        formatNumber(
                                          incomePerStanceDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {incomePerStanceDif.value &&
                                        formatNumber(incomePerStanceDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {incomePerStanceDif.percent &&
                                        formatNumber(
                                          incomePerStanceDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {incomePerStance &&
                                parseInt(incomePerStance) <
                                  parseInt(incomePerStanceAnteriorPeriode) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {incomePerStanceDif.value &&
                                        formatNumber(incomePerStanceDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {incomePerStanceDif.percent &&
                                        formatNumber(
                                          incomePerStanceDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {incomePerStanceDif.value &&
                                        formatNumber(incomePerStanceDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {incomePerStanceDif.percent &&
                                        formatNumber(
                                          incomePerStanceDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        ingreso/estancia periodo anterior:{" "}
                        {incomePerStanceAnteriorPeriode &&
                          formatNumber(incomePerStanceAnteriorPeriode)}
                        €
                      </p>
                    </div>
                  )}
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>Precio promedio vendido</p>
                    </div>
                    <div className="dataZoneCardItemGroup">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {hotelFbItemAverage &&
                            formatNumber(hotelFbItemAverage)}
                          €
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseFloat(hotelFbItemAverage) >
                        parseFloat(fixedFbItemAverage) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(hotelFbItemAverage) >
                              parseInt(fixedFbItemAverage) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {hotelFbItemAverageDif.value &&
                                      formatNumber(hotelFbItemAverageDif.value)}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {hotelFbItemAverageDif.percent &&
                                      formatNumber(
                                        hotelFbItemAverageDif.percent
                                      )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {hotelFbItemAverageDif.value &&
                                      formatNumber(hotelFbItemAverageDif.value)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {hotelFbItemAverageDif.percent &&
                                      formatNumber(
                                        hotelFbItemAverageDif.percent
                                      )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {incomePerStance &&
                              parseInt(hotelFbItemAverage) >
                                parseInt(fixedFbItemAverage) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {hotelFbItemAverageDif.value &&
                                      formatNumber(hotelFbItemAverageDif.value)}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {hotelFbItemAverageDif.percent &&
                                      formatNumber(
                                        hotelFbItemAverageDif.percent
                                      )}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {hotelFbItemAverageDif.value &&
                                      formatNumber(hotelFbItemAverageDif.value)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {hotelFbItemAverageDif.percent &&
                                      formatNumber(
                                        hotelFbItemAverageDif.percent
                                      )}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      precio promedio establecido:{" "}
                      {fixedFbItemAverage && formatNumber(fixedFbItemAverage)}€
                    </p>
                  </div>
                </div>
              </div>

              <div className="dataZoneRowGraph">
                <div className="sellPointsIncomeVsObjective">
                  <div className="dataZoneCardTitle">
                    <p>Objetivos</p>
                  </div>

                  {sellPointsGraphDataArr &&
                    sellPointsGraphDataArr.length > 0 &&
                    sellPointsGraphDataArr
                      .filter((item) => item.sellPoint === sellPointSelected)
                      .map((item, index) => (
                        <div key={index} className="sellPointItem">
                          <p className="sellPointNameGraph">{item.sellPoint}</p>
                          <div className="graphContainer">
                            {graphArr.map((_, graphIndex) => (
                              <div
                                key={graphIndex}
                                className={
                                  graphIndex < parseInt(item.objectivePercent)
                                    ? "graphON"
                                    : "graphOFF"
                                }
                              >
                                {graphIndex === 45 && (
                                  <div className="textOverlay">
                                    {formatNumber(item.income)}€
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          <p className="sellPointsData">
                            {formatNumber(item.objectivePercent)}% vs objetivo:{" "}
                            {formatNumber(item.objective)}€
                          </p>
                        </div>
                      ))}
                </div>
                <div className="sellPointsIncomeVsObjective">
                  <div className="dataZoneCardTitle">
                    <p>Ranking de productos</p>
                    <p
                      className="blue"
                      onClick={() => setAllProductsVisible(true)}
                    >
                      ver todos
                    </p>
                  </div>
                  {topLastProducs &&
                    topLastProducs.top &&
                    topLastProducs.last && (
                      <TopLastGraphic
                        data1={topLastProducs.top}
                        data2={topLastProducs.last}
                      />
                    )}
                </div>
              </div>
            </div>
          )}

          {allProductsVisible && (
            <AllProducts
              setAllProductsVisible={setAllProductsVisible}
              data={topLastProducs.all}
              title={sellPointSelected}
            />
          )}
        </div>
      ) : (
        <div className="noData">
          <PageNotVisibleAllHotels />
        </div>
      )}
    </div>
  );
};

export default SellPointsView;
