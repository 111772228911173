import React, {
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react";
import "./DashboardView.css";

import dataGet from "../../../Shared/API/dataGet";
import flechaVerde from "../../../Images/Icons/dasboard/flechaVerde.png";
import flechaRoja from "../../../Images/Icons/dasboard/flechaRoja.png";
import TopLastGraphic from "../../../Components/Graphics/TopLastGraphic";
import { useAppContext } from "../../../Shared/Context/AppContext";
import AllProducts from "./AllProducts";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const DashboardView = ({
  setActualHotelData,
  setLoading,
  dates,
  allHotelsData,
  hotelSelected,
}) => {
  const { hotelData, periodicity, selectedDepartment, datosDashboard } =
    useAppContext();
  const graphArr = Array.from({ length: 100 }, (_, index) => index + 1);
  const [data, setData] = useState(null);
  const [totalIncome, setTotalIncome] = useState(null);
  const [totalIncomeAnteriorPeriode, setTotalIncomeAnteriorPeriode] =
    useState(null);
  const [totalIncomeDif, setTotalIncomeDif] = useState({
    value: 0,
    percent: 0,
  });
  const [totalUnits, setTotalUnits] = useState(null);
  const [totalUnitsAnteriorPeridode, setTotalUnitsAnteriorPeriode] =
    useState(null);
  const [totalUnitsDif, setTotalUnitsDif] = useState({ value: 0, percent: 0 });
  const [stances, setStances] = useState(null);
  const [stancesAnteriorPeriode, setStancesAnteriorPeriode] = useState(null);
  const [stancesDif, setStancesDif] = useState({ value: 0, percent: 0 });
  const [incomePerStaff, setIncomePerStaff] = useState(null);
  const [anteriorIncomePerStaff, setAnteriorIncomePerStaff] = useState(null);
  const [incomePerStaffDif, setIncomePerStaffDif] = useState({
    value: 0,
    percent: 0,
  });
  const [ticketAverage, setTicketAverage] = useState(null);
  const [ticketAveragePeriodeAnterior, setTicketAveragePeriodeAnterior] =
    useState(null);
  const [ticketAverageDif, setTicketAverageDif] = useState({
    value: 0,
    percent: 0,
  });
  const [incomePerStance, setIncomePerStance] = useState(null);
  const [incomePerStanceAnteriorPeriode, setIncomePerStanceAnteriorPeriode] =
    useState(null);
  const [incomePerStanceDif, setIncomePerStanceDif] = useState({
    value: 0,
    percent: 0,
  });
  const [hotelFbItemAverage, setHotelFbItemAverage] = useState(null);
  // const [fixedFbItemAverage, setFixedFbItemAverage] = useState(null);
  const [hotelFbItemAverageDif, setHotelFbItemAverageDif] = useState({
    value: 0,
    percent: 0,
  });

  const [objective, setObjective] = useState([]);
  const [totalIncomeForSellPoint, setTotalIncomeForSellPoint] = useState([]);
  const [sellPointsGraphDataArr, setSellPointsGraphDataArr] = useState([]);
  const [topLastProducs, setTopLastProducs] = useState(null);
  const [objectiveType, setObjectiveType] = useState();
  const [noData, setNoData] = useState(true);
  const [allHotels, setAllHotels] = useState(false);
  const [allProductsVisible, setAllProductsVisible] = useState(false);

  const [topLastProductsState, setTopLastProductsState] = useState(null);

  useEffect(() => {
    if (hotelSelected === "1") {
      setAllHotels(true);
    } else {
      setAllHotels(false);
    }
  }, [hotelSelected]);

  // const dates = "2024-07-30,2024-07-30";
  const memorizeDate = useMemo(() => dates.split(","), [dates]);

  useEffect(() => {
    const fetchGroupData = async () => {
      const hotelsIds = allHotelsData.map((item) => item._id);

      // Llamada a todas las promesas para obtener datos de todos los hoteles
      return Promise.all(
        hotelsIds.map((hotelId) =>
          dataGet
            .getFBData(dates, hotelId, false, hotelId.idHotelGroup)
            .then((response) => ({ ...response, hotelId }))
        )
      )
        .then((responses) => {
          createObjectToShow(responses);
        })

        .catch((error) => {
          console.error("Error fetching data for group:", error);
        });
    };

    // const fetchSingleHotelData = async () => {
    //   const idHotel = hotelData._id;
    //   const groupId = hotelData.idHotelGroup._id;
    //   const pdf = false;

    //   // Llamada a la promesa para obtener datos de un solo hotel
    //   return dataGet
    //     .getFBData(dates, idHotel, pdf, groupId)
    //     .then((response) => {
    //       setData(response);
    //       setActualHotelData(response);
    //     })
    //     .catch((error) => {
    //       console.error("Error al obtener los datos:", error);
    //     });
    // };

    // Ejecutar la función de acuerdo al valor de allHotels
    const executeFetch = async () => {
      if (allHotels) {
        setLoading(true);
        await fetchGroupData();
        setLoading(false);
      } else {
        setLoading(true);
        setData(datosDashboard);
        setActualHotelData(datosDashboard);
        // await fetchSingleHotelData();
        setLoading(false);
      }
    };

    // Ejecutar la función de obtención de datos de forma asíncrona

    executeFetch();
  }, [hotelData, dates, allHotelsData, allHotels]); // Asegurarse de incluir allHotels en las dependencias

  const createObjectToShow = async (dataForGroup) => {
    setData(null);
    try {
      const arrays = {
        MTD: [],
        WTD: [],
        YTD: [],
        lastMonth: [],
        lastYear: [],
        periode: [],
        yesterday: [],
        sellPoints: [],
        monthObjectives: [],
        yearObjectives: [],
      };
      let groupHotels;

      const infoRequiredKeysInfoRequired = [
        "MTD",
        "WTD",
        "YTD",
        "lastMonth",
        "lastYear",
        "periode",
        "yesterday",
      ];
      const infoRequiredKeysData = [
        "MTD",
        "WTD",
        "YTD",
        "lastMonth",
        "lastYear",
        "periode",
        "Yesterday",
      ];
      const groupedData = {};
      const groupedDataData = {};
      const monthObjectivePerHotel = [];
      const yearObjectivePerHotel = [];

      for (const hotel of dataForGroup) {
        const monthObjective = hotel.objectives.monthObjectives.reduce(
          (acc, item) => {
            return acc + item.objective;
          },
          0
        );
        const yearObjectives = hotel.objectives.yearObjectives.reduce(
          (acc, item) => {
            return acc + item.objective;
          },
          0
        );
        monthObjectivePerHotel.push({
          sellPointName: hotel.hotelId,
          objective: monthObjective,
        });
        yearObjectivePerHotel.push({
          sellPointName: hotel.hotelId,
          objective: yearObjectives,
        });
      }
      for (const periodo of infoRequiredKeysInfoRequired) {
        const peridoDailyRequired = dataForGroup.map(
          (item) => item.dailyInfoRequired[periodo] || []
        );

        const totalCheckins = peridoDailyRequired.reduce((acc, item) => {
          return acc + (item.checkins || 0);
        }, 0);

        const totalStances = peridoDailyRequired.reduce((acc, item) => {
          return acc + (item.stances || 0);
        }, 0);

        const totalOcupation = peridoDailyRequired.reduce((acc, item) => {
          return acc + (item.ocupation || 0);
        }, 0);

        groupedData[periodo] = {
          checkins: totalCheckins,
          stances: totalStances,
          ocupation: totalOcupation / infoRequiredKeysInfoRequired.length,
        };
      }
      // Obtener datos agrupados para los diferentes periodos
      for (const periodo of infoRequiredKeysData) {
        // Obtener un array con todos los datos de `periodo` de cada objeto en `dataForGroup`

        const periodoData = dataForGroup.map((item) => {
          return {
            products: item.data[periodo] || item.data[periodo] || [],
            hotel: item.hotelId,
          };
        });

        // Aplanar el array de arrays en uno solo
        //const flattenedData = peridoDailyRequired.flat();

        // Agrupar los productos por hotel
        const hotelProducts = periodoData.reduce((acc, item) => {
          const hotelId = item.hotel;
          if (!acc[hotelId]) {
            acc[hotelId] = { pointOfSaleName: hotelId, products: [] };
          }
          acc[hotelId].products.push(
            ...item.products.flatMap((item) => item.products)
          ); // Añadir los productos del hotel actual
          return acc;
        }, {});

        // Calcular totales

        // Asignar datos calculados a los objetos agrupados
        groupHotels = Object.keys(hotelProducts);
        groupedDataData[periodo] = Object.values(hotelProducts); // Convertir los datos agrupados en un array
      }

      // Crear el objeto final de visualización
      const objectToShow = {
        dailyInfoRequired: groupedData,
        data: groupedDataData,
        dates: dates,
        sellPoints: groupHotels,
        objectives: {
          monthObjectives: monthObjectivePerHotel,
          yearObjectives: yearObjectivePerHotel,
        },
      };
      setData(objectToShow); // Actualiza el estado con el objeto de visualización
    } catch (error) {
      console.error("Error al crear el objeto para mostrar:", error);
    } finally {
    }
  };

  const prepareDataToShow = async () => {
    // setFixedFbItemAverage(hotelData.fbItemsAverage);
    let incomeTotal = 0;
    let preIncome = 0;
    let difIncome = 0;
    let difIncomePercent = 0;

    let unitsTotal = 0;
    let preUnits = 0;
    let difUnits = 0;
    let difUnitsPercent = 0;

    let stancesTotal = 0;
    let preStances = 0;
    let difStances = 0;
    let difStancesPercent = 0;

    let periodeSellPoints = [];
    let preSellPoints = [];

    let periodeStaff = 0;
    let preStaff = 0;

    let incomeStaffTotal = 0;
    let preIncomeStaffTotal = 0;
    let incomeEstaffDif = 0;
    let incomeStaffDifPercent = 0;

    let actualTicketAverage = 0;
    let preTicketAverage = 0;
    let actualTicketAverageDif = 0;
    let actualTicketAveragePercent = 0;

    let actualIncomePerStance = 0;
    let preIncomePerStance = 0;
    let actualIncomePerStanceDif = 0;
    let actualIncomePerStancePercent = 0;
    let totalIncomeForSellPointArr;
    let objectiveArr;
    let topLastProductsArr;
    let productAverage = 0;
    let productAverageDif = 0;
    let productAveragePercent = 0;
    let existData = false;
    if (data && data.data && periodicity) {
      if (parseInt(periodicity) === 1) {
        if (data.data.periode.length > 0) {
          existData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.periode);
        preIncome = data.data.Yesterday
          ? getTotalIncome(data.data.Yesterday)
          : getTotalIncome(data.data.yesterday);
        difIncome = incomeTotal - preIncome;
        difIncomePercent =
          preIncome !== 0 ? ((incomeTotal * 100) / preIncome).toFixed(0) : 100;

        periodeSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.WTD);
        preSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.lastMonth);
        periodeStaff = !allHotels
          ? await getStaff(periodeSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        preStaff = !allHotels
          ? await getStaff(preSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        unitsTotal = getTotalUnits(data.data.periode);
        preUnits = data.data.Yesterday
          ? await getTotalUnits(data.data.Yesterday)
          : await getTotalUnits(data.data.yesterday);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = ((preUnits * 100) / unitsTotal).toFixed(0);

        productAverage = incomeTotal / unitsTotal;
        productAverageDif = productAverage - hotelData.fbItemsAverage;
        productAveragePercent =
          (productAverage * 100) / hotelData.fbItemsAverage;

        stancesTotal = data.dailyInfoRequired.periode.stances;
        preStances = data.dailyInfoRequired.yesterday.stances;
        difStances = stancesTotal - preStances;
        difStancesPercent = ((preStances * 100) / stancesTotal).toFixed(0);

        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preIncome / preStaff;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;

        actualTicketAverage = unitsTotal !== 0 ? incomeTotal / unitsTotal : 0;
        preTicketAverage = preUnits !== 0 ? preIncome / preUnits : 0;

        actualTicketAverageDif = actualTicketAverage - preTicketAverage;
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preIncome / preStances;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = await getTotalIncomeForSellPoint(
          data.data.MTD
        );
        objectiveArr = !allHotels
          ? data.objectives.monthObjective
          : data.objectives.monthObjective;
        topLastProductsArr = await getTopLastProducts(data.data.periode);
        setTopLastProductsState(topLastProductsArr);
      } else if (parseInt(periodicity) === 10) {
        if (data.data.MTD.length > 0) {
          existData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = await getTotalIncome(data.data.MTD);
        preIncome = await getTotalIncome(data.data.lastMonth);
        difIncome = totalIncome - preIncome;
        difIncomePercent =
          preIncome !== 0 ? (totalIncome * 100) / preIncome : 100;
        unitsTotal = await getTotalUnits(data.data.MTD);
        preUnits = await getTotalUnits(data.data.lastMonth);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;

        productAverage = incomeTotal / unitsTotal;
        productAverageDif = productAverage - hotelData.fbItemsAverage;
        productAveragePercent =
          (productAverage * 100) / hotelData.fbItemsAverage;

        stancesTotal = data.dailyInfoRequired.MTD.stances;
        preStances = data.dailyInfoRequired.lastMonth.stances;
        difStances = (stancesTotal - preStances).toFixed(0);
        difStancesPercent =
          preStances !== 0 ? (stancesTotal * 100) / preStances : 100;
        periodeSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.MTD);
        preSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.lastMonth);
        periodeStaff = !allHotels
          ? await getStaff(periodeSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        preStaff = !allHotels
          ? await getStaff(preSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preStaff !== 0 ? preIncome / preStaff : 0;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;
        actualTicketAverage = incomeTotal / unitsTotal;
        preTicketAverage = preUnits !== 0 ? preIncome / preUnits : 0;
        actualTicketAverageDif = actualTicketAverage - preTicketAverage;
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = !allHotels
          ? await getTotalIncomeForSellPoint(data.data.MTD)
          : await getTotalIncomeForHotel(data.data.MTD);
        objectiveArr = !allHotels
          ? data.objectives.yearObjectives
          : data.objectives.yearObjectives;
        topLastProductsArr = await getTopLastProducts(data.data.MTD);
        setTopLastProductsState(topLastProductsArr);
      } else if (parseInt(periodicity) === 5) {
        if (data.data.WTD.length > 0) {
          existData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = await getTotalIncome(data.data.WTD);
        preIncome = await getTotalIncome(data.data.lastMonth);
        difIncome = incomeTotal - preIncome;
        difIncomePercent =
          preIncome !== 0 ? incomeTotal + 100 / preIncome : 100;
        unitsTotal = await getTotalUnits(data.data.WTD);
        preUnits = await getTotalUnits(data.data.lastMonth);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;

        productAverage = incomeTotal / unitsTotal;
        productAverageDif = productAverage - hotelData.fbItemsAverage;
        productAveragePercent =
          (productAverage * 100) / hotelData.fbItemsAverage;

        stancesTotal = data.dailyInfoRequired.WTD.stances;
        preStances = data.dailyInfoRequired.lastMonth.stances;
        difStances = stancesTotal - preStances;
        difStancesPercent =
          preStances !== 0
            ? ((stancesTotal * 100) / preStances).toFixed(0)
            : 100;
        periodeSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.WTD);
        preSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.lastMonth);
        periodeStaff = !allHotels
          ? await getStaff(periodeSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        preStaff = !allHotels
          ? await getStaff(preSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);

        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preIncome / preStaff;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;

        actualTicketAverage = incomeTotal / unitsTotal;
        preTicketAverage =
          preUnits !== 0 ? parseFloat(preIncome) / parseFloat(preUnits) : 0;
        actualTicketAverageDif =
          parseFloat(actualTicketAverage) - parseFloat(preTicketAverage);
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = await getTotalIncomeForSellPoint(
          data.data.WTD
        );
        objectiveArr = !allHotels
          ? data.objectives.yearObjectives
          : data.objectives.yearObjectives;
        topLastProductsArr = await getTopLastProducts(data.data.periode);
        setTopLastProductsState(topLastProductsArr);
      } else if (parseInt(periodicity) === 100) {
        if (data.data.YTD.length > 0) {
          existData = true;
        }
        setObjectiveType("anual");
        incomeTotal = await getTotalIncome(data.data.YTD);
        preIncome = await getTotalIncome(data.data.lastYear);
        difIncome = totalIncome - preIncome;
        difIncomePercent =
          preIncome !== 0 ? (totalIncome * 100) / preIncome : 100;
        unitsTotal = await getTotalUnits(data.data.YTD);
        preUnits = await getTotalUnits(data.data.lastYear);
        difUnits = unitsTotal - preUnits;
        difUnitsPercent = preUnits !== 0 ? (unitsTotal * 100) / preUnits : 100;

        productAverage = incomeTotal / unitsTotal;
        productAverageDif = productAverage - hotelData.fbItemsAverage;
        productAveragePercent =
          (productAverage * 100) / hotelData.fbItemsAverage;

        stancesTotal = data.dailyInfoRequired.YTD.stances;
        preStances = data.dailyInfoRequired.lastYear.stances;
        difStances = (stancesTotal - preStances).toFixed(0);
        difStancesPercent =
          preStances !== 0 ? (stancesTotal * 100) / preStances : 100;
        periodeSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.WTD);
        preSellPoints = !allHotels
          ? data.sellPoints
          : await getActiveSellPoints(data.data.lastMonth);
        periodeStaff = !allHotels
          ? await getStaff(periodeSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        preStaff = !allHotels
          ? await getStaff(preSellPoints, data.sellPoints)
          : await getStaffAllHotels(data.sellPoints);
        incomeStaffTotal = incomeTotal / periodeStaff;
        preIncomeStaffTotal = preStaff !== 0 ? preIncome / preStaff : 0;
        incomeEstaffDif = incomeStaffTotal - preIncomeStaffTotal;
        incomeStaffDifPercent =
          preIncomeStaffTotal !== 0
            ? (incomeStaffTotal * 100) / preIncomeStaffTotal
            : 100;
        actualTicketAverage = incomeTotal / unitsTotal;
        preTicketAverage = preUnits !== 0 ? preIncome / preUnits : 0;
        actualTicketAverageDif = actualTicketAverage - preTicketAverage;
        actualTicketAveragePercent =
          preTicketAverage !== 0
            ? (actualTicketAverage * 100) / preTicketAverage
            : 100;
        actualIncomePerStance = incomeTotal / stancesTotal;
        preIncomePerStance = preStances !== 0 ? preIncome / preStances : 0;
        actualIncomePerStanceDif = actualIncomePerStance - preIncomePerStance;
        actualIncomePerStancePercent =
          preIncomePerStance !== 0
            ? (actualIncomePerStance * 100) / preIncomePerStance
            : 100;
        totalIncomeForSellPointArr = !allHotels
          ? await getTotalIncomeForSellPoint(data.data.YTD)
          : await getTotalIncomeForHotel(data.data.YTD);
        objectiveArr = !allHotels
          ? data.objectives.yearObjectives
          : data.objectives.yearObjectives;
        topLastProductsArr = await getTopLastProducts(data.data.YTD);
        setTopLastProductsState(topLastProductsArr);
      }
    }
    // productAverage = incomeTotal / unitsTotal;
    // productAverageDif = hotelFbItemAverage - hotelData.fbItemsAverage;
    // productAveragePercent = (hotelData.fbItemsAverage * 100) / hotelFbItemAverage;
    setHotelFbItemAverage(productAverage);
    setHotelFbItemAverageDif({
      value: productAverageDif,
      percent: productAveragePercent,
    });

    setNoData(existData);
    setTopLastProducs(topLastProductsArr);
    setObjective(objectiveArr);
    setTotalIncomeForSellPoint(totalIncomeForSellPointArr);
    setTotalIncome(incomeTotal);
    setTotalIncomeAnteriorPeriode(preIncome);
    setTotalIncomeDif({ value: difIncome, percent: difIncomePercent });
    setTotalUnits(unitsTotal);
    setTotalUnitsAnteriorPeriode(preUnits);
    setTotalUnitsDif({ value: difUnits, percent: difUnitsPercent });
    setStances(stancesTotal);
    setStancesAnteriorPeriode(preStances);
    setStancesDif({ value: difStances, percent: difStancesPercent });
    setIncomePerStaff(incomeStaffTotal);
    setAnteriorIncomePerStaff(preIncomeStaffTotal);
    setIncomePerStaffDif({
      value: incomeEstaffDif,
      percent: incomeStaffDifPercent,
    });
    setTicketAverage(actualTicketAverage);
    setTicketAveragePeriodeAnterior(preTicketAverage);
    setTicketAverageDif({
      value: actualTicketAverageDif,
      percent: actualTicketAveragePercent,
    });
    setIncomePerStance(actualIncomePerStance);
    setIncomePerStanceAnteriorPeriode(preIncomePerStance);
    setIncomePerStanceDif({
      value: actualIncomePerStanceDif,
      percent: actualIncomePerStancePercent,
    });
  };
  useEffect(() => {
    prepareDataToShow();
  }, [data, periodicity, hotelSelected]);

  const getTotalIncomeForSellPoint = async (data) => {
    const resultArr = [];
    for (const sellPoint of data) {
      const totalIncome = sellPoint.products.reduce((acc, item) => {
        return acc + item.totalPrice * item.totalUnits;
      }, 0);

      const objToPush = {
        sellPoint: sellPoint.pointOfSaleName,
        income: totalIncome,
      };

      resultArr.push(objToPush);
    }
    return resultArr;
  };

  const getTotalIncomeForHotel = async (data) => {
    const resultArr = [];
    for (const hotel of data) {
      const hotelName = await dataGet.hotelId(hotel.pointOfSaleName);
      const totalIncome = hotel.products.reduce((acc, item) => {
        return acc + item.totalUnits * item.totalPrice;
      }, 0);

      const objToPush = {
        sellPoint: hotelName.hotelName,
        income: totalIncome,
      };

      resultArr.push(objToPush);
    }
    return resultArr;
  };
  const getTotalIncome = (data) => {
    if (data) {
      const productsList = data.flatMap((item) => item.products);
      const totalProductIncome = productsList.reduce((acc, item) => {
        return acc + item.totalPrice * item.totalUnits;
      }, 0);

      return totalProductIncome;
    }
  };
  const getTopLastProducts = async (data) => {
    const totalProductList = data.flatMap((item) => item.products);
  
    // Inicializa el acumulador para totalUnits
    let totalUnitsSum = 0;
  
    const groupedProducts = totalProductList.reduce((acc, product) => {
      const { productName, totalPrice, totalUnits } = product;
      const income = totalPrice * totalUnits;
  
      // Suma las unidades al total general
      totalUnitsSum += totalUnits;
  
      if (!acc[productName]) {
        acc[productName] = {
          productName,
          income: 0,
          totalUnits: 0, // También acumula las unidades por producto
        };
      }
  
      // Acumula los ingresos y las unidades por producto
      acc[productName].income += income;
      acc[productName].totalUnits += totalUnits;
  
      return acc;
    }, {});
  
    const resultArray = Object.values(groupedProducts);
    resultArray.sort((a, b) => b.income - a.income);
  
    const top = resultArray.slice(0, 5);
    const last = resultArray.slice(-5);
    last.reverse();
  
    // Devuelve también el sumatorio de totalUnits
    return {
      top: top,
      last: last,
      all: resultArray,
      totalUnitsSum: totalUnitsSum // Suma total de las unidades
    };
  };
  
  const getTotalUnits = (data) => {
    if (data) {
      const productsList = data.flatMap((item) => item.products);
      const totalProductsUnits = productsList.reduce((acc, item) => {
        return acc + item.totalUnits;
      }, 0);
      return totalProductsUnits;
    }
  };

  const getActiveSellPoints = (data) => {
    return data.map((item) => item.pointOfSale);
  };
  const getStaff = (activeSellPoints, sellPoints) => {
    const sellPointsIds = sellPoints.map((item) => item._id);
    const activeSellPointsIds = activeSellPoints.map((item) => item._id);
    const filteredSellPoints = sellPointsIds.filter((item) =>
      activeSellPointsIds.includes(item)
    );
    const thisSellPoints = sellPoints.filter((item) =>
      filteredSellPoints.includes(item._id)
    );
    const response = thisSellPoints.reduce((acc, item) => {
      return acc + item.numberStaff;
    }, 0);
    setLoading(false);
    return response;
  };
  const getStaffAllHotels = async (data) => {
    const hotelSellPoints = [];
    for (const hotel of data) {
      const sellPoints = await dataGet.sellPointsByHotel(hotel);
      hotelSellPoints.push(sellPoints);
    }
    const hotelSellPointsFlat = hotelSellPoints.flat();
    const response = hotelSellPointsFlat.reduce((acc, item) => {
      return acc + item.numberStaff;
    }, 0);
    return response;
  };
  const getThisSellPointIncome = async (hotel, totalIncomeForSellPoint) => {
    let result;
    result = await totalIncomeForSellPoint.find(
      (item) => item.sellPoint === hotel.hotelName
    );
    if (!result) {
      result = await totalIncomeForSellPoint.find(
        (item) => item.sellPoint === hotel._id
      );
    }
    return result; // Retorna el resultado para que sea accesible desde fuera de la función
  };

  const getObjectivesAllHotels = async () => {
    const dataArray = [];
    if (objective && objective.length > 0) {
      for (const sellPoint of objective) {
        const hotel = await dataGet.hotelId(sellPoint.sellPointName);
        const thisSellPointIncome = await getThisSellPointIncome(
          hotel,
          totalIncomeForSellPoint
        );

        // Verifica si thisSellPointIncome es válido antes de acceder a sus propiedades
        if (thisSellPointIncome && thisSellPointIncome.income != null) {
          const sellPointName = hotel.hotelName;
          const income = thisSellPointIncome.income;
          const objective = sellPoint.objective;
          const objectivePercent =
            (thisSellPointIncome.income * 100) / sellPoint.objective;
          const objectToPush = {
            sellPoint: sellPointName,
            income: income,
            objective: objective,
            objectivePercent: objectivePercent,
          };
          dataArray.push(objectToPush);
        } else {
          console.warn(
            `Income data is missing for sell point: ${sellPoint.sellPointName}`
          );
        }
      }
      setSellPointsGraphDataArr(dataArray);
    }
  };

  const getObjectivesOneHotel = async () => {
    const dataArray = [];
    if (objective && objective.length > 0) {
      for (const sellPoint of objective) {
        const thisSellPointIncome = totalIncomeForSellPoint.find(
          (item) => item.sellPoint === sellPoint.sellPointName
        );
        if (thisSellPointIncome) {
          const sellPointName = sellPoint.sellPointName;
          const income = thisSellPointIncome.income;
          const objective = sellPoint.objective;
          const objectivePercent =
            (thisSellPointIncome.income * 100) / sellPoint.objective;
          const objectToPush = {
            sellPoint: sellPointName,
            income: income,
            objective: objective,
            objectivePercent: objectivePercent,
          };
          dataArray.push(objectToPush);
        }
      }
      setSellPointsGraphDataArr(dataArray);
    }
  };
  useEffect(() => {
    //createObjectives();

    if (allHotels) {
      getObjectivesAllHotels();
    } else {
      getObjectivesOneHotel();
    }
  }, [totalIncomeForSellPoint, objective, allHotels]);

  return (
    <div className="dataZone">
      {noData ? (
        <div className="dataZoneContainer">
          <div className="firstLine">
            <div className="dataZoneRowGraph">
              {parseFloat(totalIncomeDif.value) !== undefined &&
                parseFloat(totalIncomeDif.percent) !== undefined && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>Ingresos totales</p>
                    </div>
                    <div className="dataZoneCardItemGroup">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {formatNumber(totalIncome)}€
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseInt(totalIncome) >
                        parseInt(totalIncomeAnteriorPeriode) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(totalIncome) >
                              parseInt(totalIncomeAnteriorPeriode) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(totalIncomeDif.value)}€
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(totalIncomeDif.percent)}%
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {formatNumber(totalIncomeDif.value)}€
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(totalIncomeDif.percent)}%
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            {parseFloat(totalIncomeDif.value) &&
                              parseFloat(totalIncomeDif.percent) && (
                                <div className="dataZoneCardItemText">
                                  {parseInt(totalIncome) >
                                  parseInt(totalIncomeAnteriorPeriode) ? (
                                    <div>
                                      <p className="dataZoneCardItemA green">
                                        {formatNumber(totalIncomeDif.value)}€
                                      </p>

                                      <p className="dataZoneCardItemB green">
                                        {formatNumber(totalIncomeDif.percent)}%
                                      </p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="dataZoneCardItemA red">
                                        {formatNumber(totalIncomeDif.value)}€
                                      </p>
                                      <p className="dataZoneCardItemB red">
                                        {formatNumber(totalIncomeDif.percent)}%
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      ventas periodo anterior:{" "}
                      {formatNumber(totalIncomeAnteriorPeriode)}€
                    </p>
                  </div>
                )}

              {totalUnitsDif.value !== undefined &&
                totalUnitsDif.percent !== undefined && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>Unidades vendidas</p>
                    </div>
                    <div className="dataZoneCardItemGroup">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {formatNumber(totalUnits)}Uds.
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {totalUnits > totalUnitsAnteriorPeridode ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>

                            <div className="dataZoneCardItemText">
                              {parseInt(totalUnits) >
                              parseInt(totalUnitsAnteriorPeridode) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {formatNumber(totalUnitsDif.value)} Uds
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(totalUnitsDif.percent)}%
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {totalUnitsDif.value} Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(totalUnitsDif.percent)}%
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseInt(totalUnits) >
                              parseInt(totalUnitsAnteriorPeridode) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {totalUnitsDif.value}
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {formatNumber(totalUnitsDif.percent)}%
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {totalUnitsDif.value} Uds
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {formatNumber(totalUnitsDif.percent)}%
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      uds. vendidas periodo anterior:{" "}
                      {formatNumber(totalUnitsAnteriorPeridode)}Uds
                    </p>
                  </div>
                )}
              {parseFloat(stancesDif.percent) && stancesDif.value && (
                <div className="dataZoneCard">
                  <div className="dataZoneCardTitle">
                    <p>Estancias</p>
                  </div>
                  <div className="dataZoneCardItemGroup">
                    <div className="dataZoneCardItem">
                      <p className="dataZoneCardItemD">
                        {formatNumber(stances)}
                      </p>
                    </div>
                    <div className="dataZoneCardItem">
                      {stances > stancesAnteriorPeriode ? (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImage"
                              src={flechaVerde}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {parseInt(stances) >
                            parseInt(stancesAnteriorPeriode) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(stancesDif.value)}
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(stancesDif.percent)}%
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {formatNumber(stancesDif.value)}€
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(stancesDif.percent)}%
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="dataZoneCardItemEvolutionContainer">
                          <div className="dataZoneCardItemEvolutionItem">
                            <img
                              className="dataZoneCardItemEvolutionImageBad"
                              src={flechaRoja}
                              alt="arriba"
                            />
                          </div>
                          <div className="dataZoneCardItemText">
                            {stances &&
                            parseInt(stances) >
                              parseInt(stancesAnteriorPeriode) ? (
                              <div>
                                <p className="dataZoneCardItemA green">
                                  {formatNumber(stancesDif.value)}
                                </p>

                                <p className="dataZoneCardItemB green">
                                  {formatNumber(stancesDif.percent)}%
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p className="dataZoneCardItemA red">
                                  {stancesDif.value && stancesDif.value}€
                                </p>
                                <p className="dataZoneCardItemB red">
                                  {formatNumber(stancesDif.percent)}%
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="dataZoneCardItemC">
                    estancias periodo anterior:{" "}
                    {formatNumber(stancesAnteriorPeriode)}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="dataZoneRowGraph">
            <div className="sellPointsIncomeVsObjective">
              <div className="kpis">
                {incomePerStaffDif.value !== undefined &&
                  incomePerStaffDif.percent !== undefined && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>Ventas por empleado</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {incomePerStaff && formatNumber(incomePerStaff)}€
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {incomePerStaff > anteriorIncomePerStaff ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseInt(incomePerStaff) >
                                parseInt(anteriorIncomePerStaff) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {incomePerStaffDif.value &&
                                        formatNumber(incomePerStaffDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {incomePerStaffDif.percent &&
                                        formatNumber(incomePerStaffDif.percent)}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {incomePerStaffDif.value &&
                                        formatNumber(incomePerStaffDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {incomePerStaffDif.percent &&
                                        formatNumber(incomePerStaffDif.percent)}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {incomePerStaff &&
                                parseInt(incomePerStaff) >
                                  parseInt(anteriorIncomePerStaff) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {incomePerStaffDif.value &&
                                        formatNumber(incomePerStaffDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {incomePerStaffDif.percent &&
                                        formatNumber(incomePerStaffDif.percent)}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {incomePerStaffDif.value &&
                                        formatNumber(incomePerStaffDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {incomePerStaffDif.percent &&
                                        formatNumber(incomePerStaffDif.percent)}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        ventas/empleado periodo anterior:{" "}
                        {anteriorIncomePerStaff &&
                          formatNumber(anteriorIncomePerStaff)}
                        €
                      </p>
                    </div>
                  )}

                {ticketAverageDif.value !== undefined &&
                  ticketAverageDif.percent !== undefined && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>Ticket medio</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {ticketAverage && formatNumber(ticketAverage)}€
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {ticketAverage > ticketAveragePeriodeAnterior ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseInt(ticketAverage) >
                                parseInt(ticketAveragePeriodeAnterior) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {ticketAverageDif.value &&
                                        formatNumber(ticketAverageDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {ticketAverageDif.percent &&
                                        formatNumber(ticketAverageDif.percent)}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {ticketAverageDif.value &&
                                        formatNumber(ticketAverageDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {ticketAverageDif.percent &&
                                        formatNumber(ticketAverageDif.percent)}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {ticketAverage &&
                                parseInt(ticketAverage) >
                                  parseInt(ticketAveragePeriodeAnterior) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {ticketAverageDif.value &&
                                        formatNumber(ticketAverageDif.value)}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {ticketAverageDif.percent &&
                                        formatNumber(ticketAverageDif.percent)}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {ticketAverageDif.value &&
                                        formatNumber(ticketAverageDif.value)}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {ticketAverageDif.percent &&
                                        formatNumber(ticketAverageDif.percent)}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        ticket medio periodo anterior:{" "}
                        {ticketAveragePeriodeAnterior &&
                          formatNumber(ticketAveragePeriodeAnterior)}
                        €
                      </p>
                    </div>
                  )}
              </div>
            </div>
            <div className="sellPointsIncomeVsObjective">
              <div className="kpis">
                {incomePerStanceDif.value && incomePerStanceDif.percent && (
                  <div className="dataZoneCard">
                    <div className="dataZoneCardTitle">
                      <p>Ingreso por estancia</p>
                    </div>
                    <div className="dataZoneCardItemGroup">
                      <div className="dataZoneCardItem">
                        <p className="dataZoneCardItemD">
                          {incomePerStance && formatNumber(incomePerStance)}€
                        </p>
                      </div>
                      <div className="dataZoneCardItem">
                        {parseFloat(incomePerStance) >
                        parseFloat(incomePerStanceAnteriorPeriode) ? (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImage"
                                src={flechaVerde}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {parseFloat(incomePerStance) >
                              parseFloat(incomePerStanceAnteriorPeriode) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {incomePerStanceDif.value &&
                                      formatNumber(incomePerStanceDif.value)}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {incomePerStanceDif.percent &&
                                      formatNumber(incomePerStanceDif.percent)}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {incomePerStanceDif.value &&
                                      formatNumber(incomePerStanceDif.value)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {incomePerStanceDif.percent &&
                                      formatNumber(incomePerStanceDif.percent)}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="dataZoneCardItemEvolutionContainer">
                            <div className="dataZoneCardItemEvolutionItem">
                              <img
                                className="dataZoneCardItemEvolutionImageBad"
                                src={flechaRoja}
                                alt="arriba"
                              />
                            </div>
                            <div className="dataZoneCardItemText">
                              {incomePerStance &&
                              parseInt(incomePerStance) >
                                parseInt(incomePerStanceAnteriorPeriode) ? (
                                <div>
                                  <p className="dataZoneCardItemA green">
                                    {incomePerStanceDif.value &&
                                      formatNumber(incomePerStanceDif.value)}
                                    €
                                  </p>

                                  <p className="dataZoneCardItemB green">
                                    {incomePerStanceDif.percent &&
                                      formatNumber(incomePerStanceDif.percent)}
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <p className="dataZoneCardItemA red">
                                    {incomePerStanceDif.value &&
                                      formatNumber(incomePerStanceDif.value)}
                                    €
                                  </p>
                                  <p className="dataZoneCardItemB red">
                                    {incomePerStanceDif.percent &&
                                      formatNumber(incomePerStanceDif.percent)}
                                    %
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="dataZoneCardItemC">
                      ingreso/estancia periodo anterior:{" "}
                      {incomePerStanceAnteriorPeriode &&
                        formatNumber(incomePerStanceAnteriorPeriode)}
                      €
                    </p>
                  </div>
                )}
                {hotelFbItemAverageDif.value &&
                  hotelFbItemAverageDif.percent && (
                    <div className="dataZoneCard">
                      <div className="dataZoneCardTitle">
                        <p>Precio promedio vendido</p>
                      </div>
                      <div className="dataZoneCardItemGroup">
                        <div className="dataZoneCardItem">
                          <p className="dataZoneCardItemD">
                            {hotelFbItemAverage &&
                              formatNumber(hotelFbItemAverage)}
                            €
                          </p>
                        </div>
                        <div className="dataZoneCardItem">
                          {parseFloat(hotelFbItemAverage) >
                          parseFloat(hotelData.fbItemsAverage) ? (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImage"
                                  src={flechaVerde}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {parseInt(hotelFbItemAverage) >
                                parseInt(hotelData.fbItemsAverage) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {hotelFbItemAverageDif.value &&
                                        formatNumber(
                                          hotelFbItemAverageDif.value
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {hotelFbItemAverageDif.percent &&
                                        formatNumber(
                                          hotelFbItemAverageDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {hotelFbItemAverageDif.value &&
                                        formatNumber(
                                          hotelFbItemAverageDif.value
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {hotelFbItemAverageDif.percent &&
                                        formatNumber(
                                          hotelFbItemAverageDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="dataZoneCardItemEvolutionContainer">
                              <div className="dataZoneCardItemEvolutionItem">
                                <img
                                  className="dataZoneCardItemEvolutionImageBad"
                                  src={flechaRoja}
                                  alt="arriba"
                                />
                              </div>
                              <div className="dataZoneCardItemText">
                                {incomePerStance &&
                                parseInt(hotelFbItemAverage) >
                                  parseInt(hotelData.fbItemsAverage) ? (
                                  <div>
                                    <p className="dataZoneCardItemA green">
                                      {hotelFbItemAverageDif.value &&
                                        formatNumber(
                                          hotelFbItemAverageDif.value
                                        )}
                                      €
                                    </p>

                                    <p className="dataZoneCardItemB green">
                                      {hotelFbItemAverageDif.percent &&
                                        formatNumber(
                                          hotelFbItemAverageDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="dataZoneCardItemA red">
                                      {hotelFbItemAverageDif.value &&
                                        formatNumber(
                                          hotelFbItemAverageDif.value
                                        )}
                                      €
                                    </p>
                                    <p className="dataZoneCardItemB red">
                                      {hotelFbItemAverageDif.percent &&
                                        formatNumber(
                                          hotelFbItemAverageDif.percent
                                        )}
                                      %
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="dataZoneCardItemC">
                        precio promedio establecido:{" "}
                        {hotelData.fbItemsAverage &&
                          formatNumber(hotelData.fbItemsAverage)}
                        €
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="dataZoneRowGraph">
            <div className="sellPointsIncomeVsObjective">
              <div className="dataZoneCardTitle">
                {/* <p>Puntos de venta distancia al objetivo {objectiveType}</p> */}
                {!allHotels ? (
                  <p>
                    Distancia al objetivo {objectiveType} por punto de venta
                  </p>
                ) : (
                  <p>Distancia al objetivo {objectiveType} por hotel </p>
                )}
              </div>
              {sellPointsGraphDataArr &&
                sellPointsGraphDataArr.length > 0 &&
                sellPointsGraphDataArr.map((item, index) => (
                  <div key={index} className="sellPointItem">
                    <p className="sellPointNameGraph">{item.sellPoint}</p>
                    <div className="graphContainer">
                      {graphArr.map((_, graphIndex) => (
                        <div
                          key={graphIndex}
                          className={
                            graphIndex < parseInt(item.objectivePercent)
                              ? "graphON"
                              : "graphOFF"
                          }
                        >
                          {graphIndex === 45 && (
                            <div className="textOverlay">
                              {formatNumber(item.income)}€ de{" "}
                              {formatNumber(item.objective)}€
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <p className="sellPointsData">
                      {formatNumber(item.objectivePercent)}% del objetivo
                    </p>
                  </div>
                ))}
            </div>
            <div className="sellPointsIncomeVsObjective">
              <div className="dataZoneCardTitle">
                <div className="rankingTitle">
                  <p>Ranking de productos</p>
                  <p
                    className="blue"
                    onClick={() => setAllProductsVisible(true)}
                  >
                    ver todos
                  </p>
                </div>
              </div>
              {topLastProducs && topLastProducs.top && topLastProducs.last && (
                <TopLastGraphic
                  data1={topLastProducs.top}
                  data2={topLastProducs.last}
                />
              )}
            </div>
          </div>
          {allProductsVisible && (
            <AllProducts
              setAllProductsVisible={setAllProductsVisible}
              data={topLastProducs.all}
               title={hotelData.hotelName}
            />
          )}
        </div>
      ) : (
        <div className="noData">
          <h1>SIN DATOS</h1>
        </div>
      )}
    </div>
  );
};

export default DashboardView;
