import React, { useEffect, useState } from "react";
import "./LogIn.css";
import api from "../../Shared/API/api";
import { useAppContext } from "../../Shared/Context/AppContext";
import logo from "../../Images/logoHbd.svg";
import dataGet from "../../Shared/API/dataGet";

const LogIn = () => {
  const {
    setRol,
    setHotelData,
    hotelGroupSelected,
    setGroupToShow,
    setGroupData,
    setHotelList,
    setDepartmentsData,
    setOnlyOneHotel,
    setOnlyOneGroup,
    setThisUser,
    setAllGroups,
  } = useAppContext();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [personalIdentIdentifier, setPersonalIndentifier] = useState("");
  const [message, setMessage] = useState(null);

  const getDepartmentsData = async () => {
    try {
      const response = await dataGet.departments();
      setDepartmentsData(response);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
    }
  };

  const fnLogIn = async () => {
    const userData = {
      user,
      password,
      personalIdentIdentifier,
    };

    try {
      const loginResponse = await api.post("/users/login", userData);

      if (loginResponse.message) {
        setMessage(loginResponse.message);
      } else {
        if (loginResponse.thisUser) {
          setThisUser(loginResponse.thisUser);
        }

        localStorage.setItem("token", loginResponse.token);
        setRol(loginResponse.user.rol);
        await getDepartmentsData();

        if (loginResponse.user.hotel) {
          const hotelResponse = await dataGet.hotelId(loginResponse.user.hotel);
          setHotelData(hotelResponse);
          setOnlyOneHotel(hotelResponse);
          setOnlyOneGroup(null);
        } else {
          setOnlyOneHotel(null);
          setOnlyOneGroup(null);
          setHotelData(null);
          setGroupToShow(null);
        }
        if (loginResponse.user.group) {
          const hotelResponse = await dataGet.groupId(loginResponse.user.group);
          const hotelsForGroup = await dataGet.hotelGroupId(hotelResponse._id);
          setGroupData(hotelsForGroup);
          setHotelData(hotelsForGroup[hotelGroupSelected]);
          setOnlyOneGroup(hotelsForGroup);
          setOnlyOneHotel(null);
        } else {
          setOnlyOneGroup(null);
          setGroupData(null);
          setGroupToShow(null);
        }
        if (!loginResponse.user.group && !loginResponse.user.hotel) {
          try {
            const allGroupsResponse = await dataGet.hotelGroup();
            setAllGroups(allGroupsResponse);

            // Obtén los hoteles para cada grupo y combínalos en un solo array
            const allHotels = [];
            for (const group of allGroupsResponse) {
              const hotelsForGroup = await dataGet.hotelGroupId(group._id);
              allHotels.push(...hotelsForGroup); // Agrega los hoteles del grupo al array principal
            }
            setHotelData(allHotels);
          } catch (error) {
            console.error("Error al obtener los grupos o hoteles:", error);
            setHotelData(null);
          }
        } else {
          setAllGroups(null);
        }
      }
    } catch (error) {
      console.error("Error en el inicio de sesión:", error);
    }
  };

  const fnCancel = () => {
    setUser("");
    setPassword("");
    setPersonalIndentifier("");
  };

  return (
    <div className="login_container">
      <div className="login_container-form">
        <div className="login_container-logo">
          <img src={logo} alt="logo hbd" />
        </div>
        {message && <p>{message}</p>}
        <div className="login_form">
          <input
            placeholder="user"
            value={user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
          />
          <input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <input
            placeholder="personal identificator"
            value={personalIdentIdentifier}
            onChange={(e) => {
              setPersonalIndentifier(e.target.value);
            }}
          />
          <div className="login_buttons-box">
            <button onClick={fnLogIn}>Login</button>
            <button onClick={fnCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
