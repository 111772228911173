import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TopLastGraphic = ({ data1, data2 }) => {
  // Crear etiquetas únicas combinadas para evitar colisiones de nombres
  const labelsData1 = data1.map((item, index) => `${item.productName}`);
  const labelsData2 = data2.map((item, index) => `${item.productName}`);

  // Combinar las etiquetas en un solo array para el eje X
  const labels = [...labelsData1, ...labelsData2];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Data Set 1',
        data: [...data1.map(item => item.income), ...Array(data2.length).fill(null)], // Rellenar con null para alinear correctamente
        backgroundColor: 'rgba(34, 139, 34, 0.8)',
        borderColor: 'rgba(34, 139, 34, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Data Set 2',
        data: [...Array(data1.length).fill(null), ...data2.map(item => item.income)], // Rellenar con null para alinear correctamente
        // backgroundColor: 'rgba(204, 0, 102, 0.8)',
        backgroundColor: 'rgba(255, 0, 0, 1)',
        borderColor: 'rgba(204, 0, 102, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-2',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        ticks: {
          callback: function (value) {
            return value + '€'; // Formatear los valores aquí
          },
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        ticks: {
          callback: function (value) {
            return value + '€'; // Formatear los valores aquí
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default TopLastGraphic;
