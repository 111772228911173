import React, { useEffect, useState } from "react";
import "./LeftNavbar.css";
// import logo from "../../Images/hbd_consulting_logo.jpg";
import { useAppContext } from "../../Shared/Context/AppContext";

const LeftNavbar = () => {
  const { selectedDepartment, setDataZoneView, onlyOneHotel, dataZoneView } =
    useAppContext();
  const [reportTypes, setReportTypes] = useState([]);
  const [rankingItemsVisible, setRankingItemsVisible] = useState(false);

  useEffect(() => {
    let reportList;
    if (selectedDepartment === "FB") {
      reportList = [
        {
          name: "Jefe departamento",
          value: "fbday",
        },
        {
          name: "Director/a",
          value: "fbweek",
        },
        // {
        //   name: "Corporativo",
        //   value: "fbmonth",
        // },
        // {
        //   name: "Incentivos",
        //   value: "fbIncentives",
        // },
      ];
    } else if (selectedDepartment === "FD") {
      reportList = [
        {
          name: "Jefe recepción",
          value: "fdday",
        },
        {
          name: "Director/a",
          value: "fdweek",
        },
        // {
        //   name: "Corporativo",
        //   value: "fdmonth",
        // },
        // {
        //   name: "Incentivos",
        //   value: "fdIncentives",
        // },
      ];
    }
    setReportTypes(reportList);
  }, [selectedDepartment]);
  const fnChangeRanking = (value) => {
    if (value === "Productos") {
      setDataZoneView("rankingProductos");
    }
    if (value === "Puntos de venta") {
      setDataZoneView("rankingSellPoints");
    }
    if (value === "Hoteles") {
      setDataZoneView("hoteles");
    }
    if (value === "Empleados") {
      setDataZoneView("rankingEmpleados");
    }
  };
  return (
    <div className="leftNavbar">
      <div
        className={
          dataZoneView !== "dashboard"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("dashboard");
          }}
        >
          Dashboard
        </p>
      </div>
      {/* <div className="leftNavbarItemGroup"> */}
      <p
        // className="leftNavbarItem"
        className={dataZoneView === 'rankingEmpleados' ||
          dataZoneView === 'rankingProductos' ||
          dataZoneView === 'rankingSellPoints' ||
          dataZoneView === 'hoteles' ? "leftNavbarItem itemSelected" : 'leftNavbarItem'
        }
        id="menu"
        onClick={() => setRankingItemsVisible(!rankingItemsVisible)}
      >
        Ranking
      </p>
      {rankingItemsVisible && (
        <div className="leftNavbarItemGroup" id="firtsMenu">
          <p
            className={dataZoneView !== 'rankingEmpleados' ? "leftNavbarItemGroupItem" : "leftNavbarItemGroupItem itemSelected"}
            id="firtsMenu"
            onClick={() => fnChangeRanking("Empleados")}
          >
            Empleados
          </p>
          <p
            className={dataZoneView !== 'rankingProductos' ? "leftNavbarItemGroupItem" : "leftNavbarItemGroupItem itemSelected"}
            onClick={() => fnChangeRanking("Productos")}
          >
            Productos
          </p>
          <p
            className={dataZoneView !== 'rankingSellPoints' ? "leftNavbarItemGroupItem" : "leftNavbarItemGroupItem itemSelected"}
            onClick={() => fnChangeRanking("Puntos de venta")}
          >
            Puntos de venta
          </p>
          {!onlyOneHotel && (
            <p
            className={dataZoneView !== 'hoteles' ? "leftNavbarItemGroupItem" : "leftNavbarItemGroupItem itemSelected"}
              onClick={() => fnChangeRanking("Hoteles")}
            >
              Hoteles
            </p>
          )}
        </div>
      )}
      {/* </div> */}
      <div
        className={
          dataZoneView !== "incentives"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("incentives");
          }}
        >
          Incentivos
        </p>
      </div>

      <div
        className={
          dataZoneView !== "products"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("products");
          }}
        >
          Productos
        </p>
      </div>
      <div
        className={
          dataZoneView !== "sellPoints"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("sellPoints");
          }}
        >
          Puntos de venta
        </p>
      </div>
      <div
        className={
          dataZoneView !== "objectives"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("objectives");
          }}
        >
          Objetivos
        </p>
      </div>
      <div
        className={
          dataZoneView !== "historics"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("historics");
          }}
        >
          Históricos
        </p>
      </div>
      <div
        className={
          dataZoneView !== "reports"
            ? "leftNavbarItem"
            : "leftNavbarItem itemSelected"
        }
      >
        <p
          onClick={() => {
            setRankingItemsVisible(false);
            setDataZoneView("reports");
          }}
        >
          Reportes
        </p>
      </div>
    </div>
  );
};

export default LeftNavbar;
