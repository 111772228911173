import api from "./api";

const dataDelete = {
   
    deleteHotelContact: async(id) => {
        return api.delete(`/hotel_contacts/delete/${id}`).then((response)=>{
            return response
        })
    },
    deleteGroupContact: async(id) => {
        return api.delete(`/group_contacts/delete/${id}`).then((response)=>{
            return response
        })
    },
   
};

export default dataDelete;