import React, { useRef, useEffect, useState } from "react";
import LeftNavbar from "../../Components/LeftNavBar/LeftNavbar.jsx";
import TopNavbar from "../../Components/TopNavbar/TopNavbar.jsx";
import DataZone from "../../Components/DataZone/DataZone.jsx";
import { useAppContext } from "../../Shared/Context/AppContext.js";
import Loading from "../Loading/Loading.jsx";

function getYesterdayDateString() {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const formattedDate = today.toISOString().split("T")[0];
  return `${formattedDate},${formattedDate}`;
}

const Dashboard = () => {
  const { loading } = useAppContext();
  const [dataToShow, setDataToShow] = useState();
  const [dates, setDates] = useState(getYesterdayDateString());
  const [allHotelsData, setAllHotelsData] = useState();
  const [hotelSelected, setHotelSelected] = useState(null);
  const [actualHotelData, setActualHotelData] = useState();

  const {allGroups} = useAppContext();
  useEffect(() => {
  },[allGroups])
  return (
    <div>
      {loading && <Loading />}

      <div id="dashboard-content">
        <TopNavbar
          setDataToShow = {setDataToShow}
          dataToShow={dataToShow}
          setDates={setDates}
          setAllHotelsData={setAllHotelsData}
          hotelSelected={hotelSelected}
          setHotelSelected={setHotelSelected}
          dates={dates}
          setActualHotelData={setActualHotelData}
        />
        <div>
          <LeftNavbar />
          <DataZone
            hotelSelected={hotelSelected}
            setDataToShow={setDataToShow}
            dates={dates}
            allHotelsData={allHotelsData}
            setActualHotelData = {setActualHotelData}
            actualHotelData={actualHotelData}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
