import React, { useEffect, useState } from "react";
import "./RankingProductos.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import descargar from "../../../Images/Icons/descargar.png";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import * as XLSX from "xlsx";

const getTextSize = (text, fontSize, font) => {
  return font.widthOfTextAtSize(text, fontSize);
};
const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const RankingProductos = ({ hotelSelected, dates, allHotelsData }) => {
  const { periodicity, setloading } = useAppContext();
  const [thisData, setThisData] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [totals, setTotals] = useState(null);

  const generateExcel = () => {
    const worksheetData = [
      ["Producto", "Ingreso", "Unidades"], // Encabezados
    ];

    // Añadir los datos del listado
    dataToShow.forEach((item) => {
      worksheetData.push([
        item.productName,
        `${item.totalPrice} €`,
        item.totalUnits,
      ]);
    });

    // Crear el worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet["!cols"] = [
      { wpx: 300 }, // Ancho para la columna ID
      { wpx: 100 }, // Ancho para la columna Descripción
      { wpx: 100 }, // Ancho para la columna Precio
    ];
    // Crear el workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Productos");

    // Generar el archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Descargar el archivo Excel
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage([600, 800]);
    const customFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 10;
    let yPosition = 750; // Ajustar la posición inicial

    // Añadir un título
    const titleLength = getTextSize(
      `Ranking de productos ${hotelName}`,
      20,
      customFont
    );
    page.drawText(`Ranking de productos ${hotelName}`, {
      x: (600 - titleLength) / 2,
      y: yPosition,
      size: 20,
      color: rgb(0, 0, 0),
    });

    yPosition -= 30; // Espacio después del título

    // Añadir encabezados de tabla

    page.drawText("Producto", {
      x: 120,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Ingreso", {
      x: 390,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Unidades", {
      x: 450,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    yPosition -= 20;

    // Añadir los datos del listado
    dataToShow.forEach((item) => {
      if (yPosition < 50) {
        // Añadir una nueva página si se alcanza el final de la página actual
        page = pdfDoc.addPage([600, 800]); // Cambia la referencia de `page` a la nueva página
        yPosition = 750; // Reinicia la posición Y en la nueva página

        // Añadir los encabezados de la tabla en la nueva página

        page.drawText("Producto", {
          x: 120,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Ingreso", {
          x: 390,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Unidades", {
          x: 450,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        yPosition -= 20;
      }

      // Añadir los datos del producto

      page.drawText(item.productName, {
        x: 120,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      const priceSize = getTextSize(
        `${item.totalPrice.toFixed(2)} €`,
        fontSize,
        customFont
      );
      page.drawText(`${item.totalPrice.toFixed(2)} €`, {
        x: 425 - priceSize,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${item.totalUnits}`, {
        x: 460,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      yPosition -= 15;
    });

    page.drawText('Total', {
      x: 120,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });
    const totalesSize = getTextSize(
      `${totals.income.toFixed(2)} €`,
      fontSize,
      customFont
    );
    page.drawText(`${totals.income.toFixed(2)} €`, {
      x: 425 - totalesSize,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });
    page.drawText(`${totals.units}`, {
      x: 460,
      y: yPosition - 10,
      size: fontSize + 3,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getHotelData = async (hotelId) => {
    try {
      const response = await dataGet.getFBData(dates, hotelId, false, false);
      return response;
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      return null;
    }
  };

  const groupProducts = (products) => {
    // Agrupar y sumar por productName
    const grouped = Object.values(
      products.reduce((acc, product) => {
        const { productName, totalPrice, totalUnits } = product;

        // Si el producto ya está en el acumulador, actualiza los valores
        if (!acc[productName]) {
          acc[productName] = {
            productName,
            totalPrice: 0,
            totalUnits: 0,
          };
        }

        // Sumar totalUnits y totalPrice
        acc[productName].totalUnits += Number(totalUnits);
        acc[productName].totalPrice += Number(totalPrice);

        return acc;
      }, {})
    );
    // Ordenar el resultado por totalPrice de forma descendente
    return grouped.sort((a, b) => b.totalPrice - a.totalPrice);
  };

  const getData = async (hotelIds) => {
    setloading(true)
    const prevResponseArr = [];
    for (const id of hotelIds) {
      const response = await getHotelData(id);
      prevResponseArr.push(response);
    }
    const dataArr = prevResponseArr.flatMap((item) => item.data);
    const MTDarr = dataArr.flatMap((item) => item.MTD);
    const WTDarr = dataArr.flatMap((item) => item.WTD);
    const YTDarr = dataArr.flatMap((item) => item.YTD);
    const periodeArr = dataArr.flatMap((item) => item.periode);

    const MTDproducts = MTDarr.flatMap((item) => item.products);
    const WTDproducts = WTDarr.flatMap((item) => item.products);
    const YTDproducts = YTDarr.flatMap((item) => item.products);
    const periodeProducts = periodeArr.flatMap((item) => item.products);

    const MTDGroupProducts = groupProducts(MTDproducts);
    const WTDGroupProducts = groupProducts(WTDproducts);
    const YTDGroupProducts = groupProducts(YTDproducts);
    const periodeGroupProducts = groupProducts(periodeProducts);

    const response = {
      MTD: MTDGroupProducts,
      WTD: WTDGroupProducts,
      YTD: YTDGroupProducts,
      periode: periodeGroupProducts,
    };
    setloading(false)
    return response;
  };
useEffect(() => {
if(hotelSelected){
  setloading(true)
  if (hotelSelected) {
    if (hotelSelected !== "1") {
      
      dataGet.hotelId(hotelSelected)
      .then((response) => {
        setHotelName(response.hotelName);
        getData([hotelSelected]).then((response) =>{
          setThisData(response)});
      });
      
    } else {
      if (allHotelsData) {
       
        const allHotelsIds = allHotelsData.map((item) => item._id);
        getData(allHotelsIds).then((response) => setThisData(response));
        setHotelName("todos los hoteles");
   
      }
    }
  }
  setloading(false)
}
},[])
  useEffect(() => {
    setloading(true)
    if (hotelSelected) {
      if (hotelSelected !== "1") {
        dataGet.hotelId(hotelSelected).then((response) => {
          setHotelName(response.hotelName);
        });
        getData([hotelSelected]).then((response) => {
          setThisData(response)});
     
      } else {
        if (allHotelsData) {
          const allHotelsIds = allHotelsData.map((item) => item._id);
          getData(allHotelsIds).then((response) => setThisData(response));
          setHotelName("todos los hoteles");
        }
      }
    }

    setloading(false)
  }, [hotelSelected, allHotelsData]);

  useEffect(() => {
    setloading(true)
    if (thisData && periodicity && periodicity === "1") {
      setDataToShow(thisData.periode);
    } else if (thisData && periodicity && periodicity === "5") {
      setDataToShow(thisData.WTD);
    } else if (thisData && periodicity && periodicity === "10") {
      setDataToShow(thisData.MTD);
    } else if (thisData && periodicity && periodicity === "100") {
      setDataToShow(thisData.YTD);
    }
    setloading(false)
  }, [periodicity, hotelSelected, thisData]);

  useEffect(() => {
   if(dataToShow){ 
    const totalIncome = dataToShow.reduce((acc, item) => {
      return acc + item.totalPrice;
    }, 0);
    const totalUnits = dataToShow.reduce((acc, item) => {
      return acc + item.totalUnits;
    }, 0)
    setTotals({income: totalIncome, units: totalUnits})}
  },[dataToShow])

  const fnDownloadFile = () => {
    const fileTypeInt = parseInt(fileType);
    if (fileTypeInt !== 0) {
      if (fileTypeInt === 1) {
        generatePDF();
      } else if (fileTypeInt === 2) {
        generateExcel();
      }
    }
  };
  return (
    <div className="dataZone">
      <div className="rankingProductsContainer">
        <div className="control"></div>
 
          <div className="downloadOptionsDownload">
            <select onChange={(e) => setFileType(e.target.value)}>
              <option value={0}>Formato</option>
              <option value={1}>PDF</option>
              <option value={2}>EXCEL</option>
            </select>
            <button onClick={() => fnDownloadFile()}>Descargar</button>
          </div>
        
        <div className="productsList">
          <p className="productsTitle">Productos vendidos</p>
          <div className="rankingItem">
            <p className="colProduct colTitle">Producto</p>
            <p className="colIncome colTitle">Ingreso</p>
            <p className="colUnits colTitle">Unidades</p>
          </div>
          <div className="rankingItem" id="rankingTotales">
          <p className="colProduct">TOTALES</p>
                {totals && totals.income && <p className="colIncome">{formatNumber(totals.income)}€</p>}
                {totals && totals.units && <p className="colUnits" >{formatNumber(totals.units)}</p>}
          </div>
          {dataToShow &&
            dataToShow.length > 0 &&
            dataToShow.map((item, index) => (
              <div key={index} className="rankingItem">
                <p className="colProduct">{item.productName}</p>
                <p className="colIncome">{formatNumber(item.totalPrice)}€</p>
                <p className="colUnits">{formatNumber(item.totalUnits)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RankingProductos;
