import React, { useEffect, useState, useSyncExternalStore } from "react";
import "./ObjectivesView.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
  "Anual",
];

const dropDuplicates = (array) => {
  const unicArray = array.filter(
    (value, index) => array.indexOf(value) === index
  );
  return unicArray;
};
function getCurrentAndNextYear() {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  return [currentYear, nextYear];
}

const ObjectivesView = () => {
  const { hotelData, selectedDepartment } = useAppContext();
  const [hotelObjectives, setHotelObjectives] = useState(null);
  const [hotelObjectivesFiltered, setHotelObjectivesFiltered] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [yearSelected, setYearSelected] = useState(null);
  const [monthSelected, setMonthSelected] = useState(null);
  const [addObjectiveVisible, setAddObjectiveVisible] = useState(false);
  const [hotelSellPoints, setHotelSellPoints] = useState(null);
  const [newYearList, setNewYearList] = useState(getCurrentAndNextYear());
  const [monthyObjective, setMonthyObjective] = useState(null);
  const [objectiveDetailVisible, setObjectivedetailVisible] = useState(false);
  const [sellPointObjective, setSellPointObjective] = useState({
    idSellPoint: "",
    objective: 0,
    percent: 0,
  });

  const getHotelSellPoints = async () => {
    const response = await dataGet.sellPointsByHotel(hotelData._id);
    const sellPointsNames = response.map((item) => {
      return { name: item.sellPointName, id: item._id };
    });
    setHotelSellPoints(sellPointsNames);
  };
  const getHotelObjectives = async () => {
    const response = await dataGet.hotelObjectives(hotelData._id);
    response.reverse();
    const years = response.map((item) => item.year);
    setYearList(dropDuplicates(years));
    setHotelObjectives(response);
    setHotelObjectivesFiltered(response);
  };
  useEffect(() => {
    if (hotelData && selectedDepartment === "FB") {
      getHotelObjectives();
      getHotelSellPoints();
    }
  }, [hotelData]);
  useEffect(() => {
    if (
      yearSelected &&
      monthSelected &&
      yearSelected !== "50" &&
      monthSelected !== "50"
    ) {
      filterObjectives();
    } else {
      setHotelObjectivesFiltered(hotelObjectives);
    }
  }, [yearSelected, monthSelected]);

  useEffect(() => {
    if (monthyObjective && monthyObjective !== "") {
      setObjectivedetailVisible(true);
    } else {
      setObjectivedetailVisible(false);
    }
  }, [monthyObjective]);
  const filterObjectives = () => {
    if (parseInt(monthSelected) + 1 < 13) {
      setHotelObjectivesFiltered(hotelObjectives);
      const filteredObjectives = hotelObjectives.filter((item) => {
        const actualMonth = parseInt(monthSelected) + 1;
        const result =
          item.year === yearSelected.toString() &&
          item.month === actualMonth.toString();
        return result;
      });

      setHotelObjectivesFiltered(filteredObjectives);
    } else {
      setHotelObjectivesFiltered(hotelObjectives);
      const filteredObjectives = hotelObjectives.filter((item) => {
        const result = item.year === yearSelected.toString();
        return result;
      });
      const totalYearObjective = filteredObjectives.reduce((acc, item) => {
        return acc + parseFloat(item.totalObjective);
      }, 0);
      const objectiveArr = [];
      for (const month of filteredObjectives) {
        const monthObjective = month.totalObjective;
        for (const sellPoint of month.fb) {
          const sellPointName = sellPoint.idSellPoint.sellPointName;
          const sellPointObjective = (monthObjective * sellPoint.percent) / 100;
          objectiveArr.push({
            sellPoint: sellPointName,
            monthObjective: sellPointObjective,
          });
        }
      }
      const result = Object.values(
        objectiveArr.reduce((acc, { sellPoint, monthObjective }) => {
          // Si el punto de venta ya existe, sumamos el mes
          if (!acc[sellPoint]) {
            acc[sellPoint] = { sellPoint, yearObjective: 0 };
          }
          // Sumar el mes al objetivo anual
          acc[sellPoint].yearObjective += monthObjective;
          return acc;
        }, {})
      );

      const retorno = {
        month: '40',
        year: yearSelected,
        totalObjective: totalYearObjective,
        fb: result.map((item) => {
          return {
            idSellPoint: {
              sellPointName: item.sellPoint,
            },
            percent: item.yearObjective * 100 / totalYearObjective,
          }
        })
          
      }
      setHotelObjectivesFiltered([retorno]);
    }
  };
  const fnSaveNewObjectives = (idSellPOint, objectiveType, objectiveValue) => {

  };
  return (
    <div className="dataZone">
      <div className="objectivesContainer">
        <div className="filterBox">
          <p className="filterBoxTitle">Filtrar objetivos</p>
          <div className="filterBoxSelectsBox">
            <div className="filterBoxSelects">
              <select
                onChange={(e) => setYearSelected(e.target.value)}
                className="filterBoxSelectItem"
              >
                <option value={50}>Año</option>
                {yearList &&
                  yearList.length > 0 &&
                  yearList.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
              </select>
              <select
                onChange={(e) => setMonthSelected(e.target.value)}
                className="filterBoxSelectItem"
              >
                <option value={50}>Mes</option>
                {months &&
                  months.length > 0 &&
                  months.map((item, index) => (
                    <option key={index} value={index}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            {/* <div>
              <p onClick={() => setAddObjectiveVisible(!addObjectiveVisible)}>
                Agregar objetivo
              </p>
            </div> */}
          </div>
        </div>
        {addObjectiveVisible && (
          <div className="objectivesTitle newBack">
            <p className="detailText">Crear nuevo objetivo</p>
            <div className="objectivesTime">
              <select className="filterBoxSelectItem">
                <option>Año</option>
                {newYearList &&
                  newYearList.length > 0 &&
                  newYearList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <select className="filterBoxSelectItem">
                <option>Mes</option>
                {months &&
                  months.map((item, index) => (
                    <option key={index} value={index}>
                      {item}
                    </option>
                  ))}
              </select>
              <input
                type="number"
                placeholder="objetivo mensual"
                className="filterBoxSelectItem"
                onChange={(e) => setMonthyObjective(e.target.value)}
              />
            </div>
            {objectiveDetailVisible && (
              <div className="objectivesGroup ">
                {hotelSellPoints &&
                  hotelSellPoints.length > 0 &&
                  hotelSellPoints.map((item, index) => (
                    <div key={index} className="objectiveDetail">
                      <p className="detailText">{item.name}</p>
                      <input
                        type="number"
                        placeholder="objetivo"
                        className="filterBoxSelectItem"
                        onChange={(e) =>
                          fnSaveNewObjectives(item.id, "income", e.target.value)
                        }
                      />
                      <input
                        type="number"
                        placeholder="porcentje"
                        className="filterBoxSelectItem"
                        onChange={(e) =>
                          fnSaveNewObjectives(
                            item.id,
                            "percent",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
                <button className="buttonAdd">Grabar</button>
              </div>
            )}
          </div>
        )}
        {hotelObjectivesFiltered &&
          hotelObjectivesFiltered.length > 0 &&
          hotelObjectivesFiltered.map((objective, index) => (
            <div key={index} className="objectivesTitle">
              <div className="objectivesTime">
                <p>{objective.year}</p>
                <p>{months[parseInt(objective.month) - 1]}</p>
                <p className="detailValue">{formatNumber(objective.totalObjective)}€</p>
              </div>
              <div className="objectivesGroup">
                {objective.fb &&
                  objective.fb.map((itemFb, indexFb) => (
                    <div key={indexFb} className="objectiveDetail">
                      <p className="detailText">
                        {itemFb.idSellPoint.sellPointName}
                      </p>
                      {itemFb.percent !== 0 ? (
                        <p className="detailValue">
                          {formatNumber(
                            (objective.totalObjective * itemFb.percent) / 100
                            
                          )}
                          €
                        </p>
                      ) : (
                        <p className="detailValue">0</p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ObjectivesView;
