import React, { useEffect, useState } from "react";
import "./RankingSellPoints.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};

const RankingSellPoint = ({ hotelSelected, dates, allHotelsData }) => {
  const { periodicity, setloading } = useAppContext();
  const [thisData, setThisData] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const getHotelData = async (hotelId) => {
    try {
      const response = await dataGet.getFBData(dates, hotelId, false, false);
      return response;
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      return null;
    }
  };

  const groupProducts = (products) => {
    // Agrupar y sumar por productName
    const grouped = Object.values(
      products.reduce((acc, product) => {
        const { productName, totalPrice, totalUnits } = product;

        // Si el producto ya está en el acumulador, actualiza los valores
        if (!acc[productName]) {
          acc[productName] = {
            productName,
            totalPrice: 0,
            totalUnits: 0,
          };
        }

        // Sumar totalUnits y totalPrice
        acc[productName].totalUnits += Number(totalUnits);
        acc[productName].totalPrice += Number(totalPrice);

        return acc;
      }, {})
    );
    // Ordenar el resultado por totalPrice de forma descendente
    return grouped.sort((a, b) => b.totalPrice - a.totalPrice);
  };

  const getTotalSellPointIncome = (dataArray) => {
    const resultIncome = dataArray.reduce((acc, item) => {
      return acc + item.totalPrice;
    }, 0);
    const resultUnits = dataArray.reduce((acc, item) => {
      return acc + item.totalUnits;
    }, 0);
    return { income: resultIncome, units: resultUnits };
  };
  const getData = async (hotelIds) => {
    const prevResponseArr = [];
    // Obtener datos de cada hotel
    for (const id of hotelIds) {
      const response = await getHotelData(id);
      prevResponseArr.push({ response: response, idHotel: id });
    }

    const resultArr = [];

    // Procesar los datos obtenidos de cada hotel
    
    for (const hotel of prevResponseArr) {
      const thisHotel = await dataGet.hotelId(hotel.idHotel);
      const hotelName = thisHotel.hotelName;
      const { MTD, WTD, YTD, periode } = hotel.response.data;

      // Inicializar objeto de hotel en el resultado
      const result = {
        [hotelName]: {
          MTD: {},
          WTD: {},
          YTD: {},
          periode: {},
        },
      };

      // Función para procesar los puntos de venta y calcular ingresos
      const processSellPoints = (sellPoints, period) => {
        for (const sellPoint of sellPoints) {
          const sellPointName = sellPoint.pointOfSaleName;
          const products = sellPoint.products;
          const sellPointIncome = groupProducts(products);
          result[hotelName][period][sellPointName] =
            getTotalSellPointIncome(sellPointIncome);
        }
      };

      // Procesar cada período
      processSellPoints(MTD, "MTD");
      processSellPoints(WTD, "WTD");
      processSellPoints(YTD, "YTD");
      processSellPoints(periode, "periode");

      // Añadir el resultado del hotel al array de resultados
      resultArr.push(result);
    }
    return resultArr;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (hotelSelected) {
        try {
          setloading(true); // Mover setloading al principio
  
          if (hotelSelected !== "1") {
            const response = await getData([hotelSelected]);
            setThisData(response);
          } else if (allHotelsData) {
            const allHotelsIds = allHotelsData.map((item) => item._id);
            const response = await getData(allHotelsIds);
            setThisData(response);
          }
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        } finally {
          setloading(false); // Asegurarse de que setloading(false) se llame siempre después
        }
      }
    };
  
    fetchData();
  }, [hotelSelected, allHotelsData]);
  
  useEffect(() => {
    const processHotelData = async () => {
      try {
        setloading(true);
        if (thisData) {
          const result = transformHotelData(thisData);
          const arrResult = [];
  
          for (const hotel of result) {
            const hotelName = hotel.hotelName;
            for (const sellPoint of hotel.sellPoints) {
              const objToPush = {
                hotel: hotelName,
                sellPoint: sellPoint.sellPoint,
                MTD: sellPoint.MTD,
                WTD: sellPoint.WTD,
                YTD: sellPoint.YTD,
                periode: sellPoint.periode,
              };
              arrResult.push(objToPush);
            }
          }
  
          let prepareDataToShow = [];
          switch (periodicity) {
            case "1":
              prepareDataToShow = sortByIncome(arrResult, "periode");
              break;
            case "5":
              prepareDataToShow = sortByIncome(arrResult, "WTD");
              break;
            case "10":
              prepareDataToShow = sortByIncome(arrResult, "MTD");
              break;
            case "100":
              prepareDataToShow = sortByIncome(arrResult, "YTD");
              break;
            default:
              break;
          }
          setDataToShow(prepareDataToShow);
        }
      } catch (error) {
        console.error("Error procesando los datos:", error);
      } finally {
        setloading(false);
      }
    };
  
    processHotelData();
  }, [periodicity, thisData]);
  

  const transformHotelData = (hotelsArray) => {
    return hotelsArray.map((hotelObj) => {
      const hotelName = Object.keys(hotelObj)[0];
      const hotelData = hotelObj[hotelName];
      const sellPoints = [];
      const processPeriod = (periodData, periodName) => {
        
        for (const [sellPointName, { income, units }] of Object.entries(
          periodData
        )) {
          let existingSellPoint = sellPoints.find(
            (sp) => sp.sellPoint === sellPointName
          );
          if (!existingSellPoint) {
            existingSellPoint = {
              sellPoint: sellPointName,
              MTD: { income: 0, units: 0 },
              WTD: { income: 0, units: 0 },
              YTD: { income: 0, units: 0 },
              periode: { income: 0, units: 0 },
            };
            sellPoints.push(existingSellPoint);
          }
          existingSellPoint[periodName].income += income;
          existingSellPoint[periodName].units += units;
        }
        
      };
      processPeriod(hotelData.MTD, "MTD");
      processPeriod(hotelData.WTD, "WTD");
      processPeriod(hotelData.YTD, "YTD");
      processPeriod(hotelData.periode, "periode");
      return {
        hotelName,
        sellPoints,
      };
    });
  };

  const sortByIncome = (data, period) => {
    const validPeriods = ["MTD", "WTD", "YTD", "periode"];
    if (!validPeriods.includes(period)) {
      throw new Error(
        `El período ${period} no es válido. Usa: ${validPeriods.join(", ")}`
      );
    }
    const sorted = data.sort((a, b) => b[period].income - a[period].income);
    return sorted.map((item) => ({
      hotel: item.hotel,
      sellPoint: item.sellPoint,
      data: item[period], // Devuelve solo el período que se está ordenando
    }));
  };

  useEffect(() => {
    if (thisData) {
      const result = transformHotelData(thisData);
      const arrResult = [];

      for (const hotel of result) {
        const hotelName = hotel.hotelName;
        for (const sellPoint of hotel.sellPoints) {
          const objToPush = {
            hotel: hotelName,
            sellPoint: sellPoint.sellPoint,
            MTD: sellPoint.MTD,
            WTD: sellPoint.WTD,
            YTD: sellPoint.YTD,
            periode: sellPoint.periode,
          };
          arrResult.push(objToPush);
        }
      }

      if (thisData && periodicity && periodicity === "1") {
        const prepareDataToShow = sortByIncome(arrResult, "periode");
        setDataToShow(prepareDataToShow);
      } else if (thisData && periodicity && periodicity === "5") {
        const prepareDataToShow = sortByIncome(arrResult, "WTD");
        setDataToShow(prepareDataToShow);
      } else if (thisData && periodicity && periodicity === "10") {
        const prepareDataToShow = sortByIncome(arrResult, "MTD");
        setDataToShow(prepareDataToShow);
      } else if (thisData && periodicity && periodicity === "100") {
        const prepareDataToShow = sortByIncome(arrResult, "YTD");
        setDataToShow(prepareDataToShow);
      }
    }
  }, [periodicity, thisData]);
  return (
    <div className="dataZone">
      <div className="rankingProductsContainer">
        <div className="productsList">
          <p className="productsTitle">Ranking de puntos de venta</p>
          <div className="rankingItem">
            <p className="colProduct colTitle">Hotel</p>
            <p className="colProduct colTitle">Punto de venta</p>
            <p className="colIncome colTitle">Ingreso</p>
            <p className="colUnits colTitle">Unidades</p>
          </div>
          {dataToShow &&
            dataToShow.length > 0 &&
            dataToShow.map((item, index) => (
              <div key={index} className="rankingItem">
                <p className="colProduct">{item.hotel}</p>
                <p className="colProduct">{item.sellPoint}</p>
                <p className="colUnits">{formatNumber(item.data.income)}€</p>
                <p className="colUnits">{formatNumber(item.data.units)}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RankingSellPoint;
