import { createContext, useContext, useState } from "react";
import moment from 'moment';

const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const today = moment();
  const startMonth = today.clone().startOf('month'); 
  const oneYearAgo = today.clone().subtract(1, 'year'); 
  const startOfMonthOneYearAgo = oneYearAgo.clone().startOf('month');

  const [rol, setRol] = useState(null);
  const [groupToShow, setGroupToShow] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);
  const [thisUser, setThisUser] = useState(null)

  const [groupData, setGroupData] = useState(null);
  const [hotelData, setHotelData] = useState(null);
  const [hotelList, setHotelList] = useState(null);
  const [departmentsData, setDepartmentsData] = useState(null);

  const [initDateFilter, setInitDateFilter] = useState(startMonth);
  const [endDateFilter, setEndDateFilter] = useState(today);
  const [intiDateFilterHistoric, setInitDateFilterHistoric] = useState(oneYearAgo);
  const [endDateFilterHistoric, setEndDateFilterHistoric] = useState(startOfMonthOneYearAgo);

  const [fbDailyRegisterData, setFbDailyRegisterData] = useState(null);
  const [dailyInfoRequired, setDailyInfoRequired] = useState(null);
  const [fbDailyRegisterDataLastYear, setFbDailyRegisterDataLastYear] = useState(null);
  const [dailyInfoRequiredLastYear, setDailyInfoRequiredLastYear] = useState(null)

  const [hotelLogo, setHotelLogo] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [calendarVisible, setCalendarVisible] = useState(false);

  const [dataForDataZone, setDataForDataZone] = useState(null);
  const [downloadPdf, setDownloadPdf] = useState(false);

  const [loading, setloading] = useState(false);
  const [hotelGroupSelected, setHotelGroupSelected] = useState(0);

  const [dataZoneHeigh, setDataZoneHeigh] = useState(null);
  const [hotelSellPoints, setHotelSellPoints] = useState();
  const [periodicity, setPeriodicity] = useState(null);
  const [dataZoneView, setDataZoneView] = useState('dashboard')
  const [onlyOneHotel, setOnlyOneHotel] = useState(null);
  const [onlyOneGroup, setOnlyOneGroup] = useState(null);
  const [allGroups, setAllGroups] = useState(null);

  const [datosDashboard, setDatosDashboard] = useState(null)

  return (
    <AppContext.Provider
      value={{
        onlyOneHotel,
        setOnlyOneHotel,
        onlyOneGroup, 
        setOnlyOneGroup,
        allGroups,
        setAllGroups,
        rol,
        setRol,
        thisUser,
        setThisUser,
        groupToShow,
        setGroupToShow,
        dataToShow,
        setDataToShow,
        groupData,
        setGroupData,
        hotelData,
        setHotelData,
        hotelList,
        setHotelList,
        departmentsData,
        setDepartmentsData,

        intiDateFilterHistoric, 
        setInitDateFilterHistoric,
        endDateFilterHistoric,
        setEndDateFilterHistoric,
        
        initDateFilter,
        setInitDateFilter,
        setEndDateFilter,
        endDateFilter,

        fbDailyRegisterData,
        setFbDailyRegisterData,
        dailyInfoRequired,
        setDailyInfoRequired,

        fbDailyRegisterDataLastYear,
        setFbDailyRegisterDataLastYear,
        dailyInfoRequiredLastYear,
        setDailyInfoRequiredLastYear,

        hotelLogo,
        setHotelLogo,
        selectedDepartment,
        setSelectedDepartment,
        calendarVisible,
        setCalendarVisible,

        dataForDataZone,
        setDataForDataZone,

        downloadPdf,
        setDownloadPdf,

        loading,
        setloading,

        hotelGroupSelected,
        setHotelGroupSelected,

        dataZoneHeigh,
        setDataZoneHeigh, 
        hotelSellPoints,
        setHotelSellPoints,
        periodicity,
        setPeriodicity,

        dataZoneView,
        setDataZoneView,

        datosDashboard,
        setDatosDashboard
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
