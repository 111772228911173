import React, { useEffect, useState } from "react";
import "./ProductsView.css";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
import { PDFDocument, rgb } from "pdf-lib";
import * as XLSX from "xlsx";
import descargar from "../../../Images/Icons/descargar.png";

const Products = () => {
  const { hotelData } = useAppContext();
  const [data, setData] = useState(null);
  const [dataNew, setDataNew] = useState(null);
  const [dataFiltered, setDataFiltered] = useState(null);
  const [dataNewFiltered, setDataNewFiltered] = useState(null);
  const [dataIncome, setDataIncome] = useState(null);
  const [dataNewIncome, setDataNewIncome] = useState(null);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [showNotPossible, setShowNotPosible] = useState(false);  

  const generateExcel = () => {
    const worksheetData = [
      ["ID", "Descripción", "Precio", "Nuevo"], // Encabezados
    ];

    // Añadir los datos del listado
    dataFiltered.forEach((item) => {
      const isNew = item.isHbdItem ? "X" : "";
      worksheetData.push([
        item.idUpsellItem,
        item.itemDescription,
        `${item.precio} €`,
        isNew,
      ]);
    });

    // Crear el worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet['!cols'] = [
      { wpx: 100 }, // Ancho para la columna ID
      { wpx: 300 }, // Ancho para la columna Descripción
      { wpx: 100 }, // Ancho para la columna Precio
      { wpx: 50 },  // Ancho para la columna Nuevo
    ];
    // Crear el workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Productos");

    // Generar el archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Descargar el archivo Excel
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage([600, 800]);

    const fontSize = 10;
    let yPosition = 750; // Ajustar la posición inicial

    // Añadir un título
    page.drawText(`Listado de productos: ${hotelData.hotelName}`, {
      x: 50,
      y: yPosition,
      size: 20,
      color: rgb(0, 0, 0),
    });

    yPosition -= 30; // Espacio después del título

    // Añadir encabezados de tabla
    page.drawText("ID", {
      x: 50,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Descripción", {
      x: 120,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Precio", {
      x: 400,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });
    page.drawText("Nuevo", {
      x: 450,
      y: yPosition,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    yPosition -= 20;

    // Añadir los datos del listado
    dataFiltered.forEach((item) => {
      if (yPosition < 50) {
        // Añadir una nueva página si se alcanza el final de la página actual
        page = pdfDoc.addPage([600, 800]); // Cambia la referencia de `page` a la nueva página
        yPosition = 750; // Reinicia la posición Y en la nueva página

        // Añadir los encabezados de la tabla en la nueva página
        page.drawText("ID", {
          x: 50,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Descripción", {
          x: 120,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Precio", {
          x: 400,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        page.drawText("Nuevo", {
          x: 450,
          y: yPosition,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        yPosition -= 20;
      }

      const isNew = item.isHbdItem ? "X" : "";
      // Añadir los datos del producto
      page.drawText(item.idUpsellItem, {
        x: 50,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      page.drawText(item.itemDescription, {
        x: 120,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${(item.precio).toFixed(2)} €`, {
        x: 400,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${isNew}`, {
        x: 460,
        y: yPosition,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      yPosition -= 15;
    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products_list.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (hotelData) {
      getProductsData(hotelData);
    }
  }, [hotelData]);

  const getProductsData = async (hotelData) => {
    const idHotel = hotelData._id;
    dataGet.fbItems(idHotel).then((response) => {
      const uniqueProducts = response.filter(
        (product, index, self) =>
          index ===
          self.findIndex((p) => p.itemDescription === product.itemDescription)
      );
      const newProducts = uniqueProducts.filter((item) => item.isHbdItem);

      // Ordenando productos
      uniqueProducts.sort((a, b) =>
        a.itemDescription.localeCompare(b.itemDescription)
      );
      newProducts.sort((a, b) =>
        a.itemDescription.localeCompare(b.itemDescription)
      );

      setData(uniqueProducts);
      setDataNew(newProducts);
      setDataFiltered(uniqueProducts);
      setDataNewFiltered(newProducts);
    });
  };
  useEffect(() => {
    if (data && dataNew) {
      const totalIncomeData = data.reduce((acc, item) => {
        return acc + item.precio;
      }, 0);
      const totalIncomeDataNew = dataNew.reduce((acc, item) => {
        return acc + item.precio;
      }, 0);

      setDataIncome((totalIncomeData / data.length).toFixed(2));
      setDataNewIncome((totalIncomeDataNew / dataNew.length).toFixed(2));
    }
  }, [data, dataNew]);
  const fnFilter = (e) => {
    if (e === "") {
      setDataFiltered(data);
      setDataNewFiltered(dataNew);
    } else {
      const datasFiltered = data.filter(
        (item) =>
          item.itemDescription.toLowerCase().includes(e.toLowerCase()) ||
          item.idUpsellItem.includes(e)
      );
      const datasNewFiltered = dataNew.filter(
        (item) =>
          item.itemDescription.toLowerCase().includes(e.toLowerCase()) ||
          item.idUpsellItem.includes(e)
      );
      setDataFiltered(datasFiltered);
      setDataNewFiltered(datasNewFiltered);
    }
  };

  const fnOrderBy = (e) => {
    let sortedData = [...dataFiltered]; // Copia de dataFiltered para no mutar el estado original
    let sortedNewData = [...dataNewFiltered];

    if (e === "2") {
      // Ordenar por Nombre (asumiendo que el campo de nombre es itemDescription)
      sortedData.sort((a, b) =>
        a.itemDescription.localeCompare(b.itemDescription)
      );
      sortedNewData.sort((a, b) =>
        a.itemDescription.localeCompare(b.itemDescription)
      );
    } else if (e === "3") {
      // Ordenar por Id (asumiendo que el campo de ID es idUpsellItem)
      sortedData.sort(
        (a, b) => parseInt(a.idUpsellItem) - parseInt(b.idUpsellItem)
      );
      sortedNewData.sort(
        (a, b) => parseInt(a.idUpsellItem) - parseInt(b.idUpsellItem)
      );
    } else if (e === "4") {
      // Ordenar por Precio (asumiendo que el campo de precio es precio)
      sortedData.sort((a, b) => parseFloat(a.precio) - parseFloat(b.precio));
      sortedNewData.sort((a, b) => parseFloat(a.precio) - parseFloat(b.precio));
    }

    setDataFiltered(sortedData); // Actualizar el estado con los datos ordenados
    setDataNewFiltered(sortedNewData);
  };
const fnDownloadFile = () =>{
  const fileTypeInt = parseInt(fileType)
  if(fileTypeInt !== 0){
    if(fileTypeInt === 1){
      generatePDF();
    }else if(fileTypeInt === 2){
      generateExcel()
    }
  }
}
  return (
    <div className="dataZone">
      <div className="productsZoneContainer">
        <div className="control">
        <select
          className="inputFilter"
          onChange={(e) => fnOrderBy(e.target.value)}
        >
          <option value={2}>Nombre</option>
          <option value={3}>Id</option>
          <option value={4}>Precio</option>
        </select>
          <input
            className="inputFilter"
            onChange={(e) => fnFilter(e.target.value)}
            placeholder="Buscar producto"
          />

          <button
            onClick={() => setDownloadVisible(!downloadVisible)}
            className="btnDownload"
            title="Descargar listado"
          >
            <img src={descargar} alt="download" />
          </button>
        </div>
        {downloadVisible && (
          <div className="downloadOptions">
            <select onChange={(e)=>setFileType(e.target.value)}>
              <option value={0}>Formato</option>
              <option value={1}>PDF</option>
              <option value={2}>EXCEL</option>
            </select>
            <button onClick={()=>fnDownloadFile()}>Descargar</button>
          </div>
        )}

     

        <div className="productsZone">
          <div className="productsList">
            {dataIncome && (
              <div>
                <h3>Productos controlados </h3>
                <div className="productListResumen">
                  <h4>Nº productos: {data?.length}</h4>
                  <h4>Precio medio: {dataIncome} € </h4>
                </div>
              </div>
            )}

            {dataFiltered?.map((item, index) => (
              <div key={index} className="producListItem">
                <p>{item.idUpsellItem}</p>
                <p className="productListDescription">{item.itemDescription}</p>
                <p className="productListPrice">{(item.precio).toFixed(2)} €</p>
              </div>
            ))}
          </div>
          <div className="productsList">
            <div>
              <h3>Productos nuevos </h3>
              <div className="productListResumen">
                <h4>Nº productos: {dataNew?.length}</h4>
                <h4>Precio medio: {dataNewIncome} € </h4>
              </div>
            </div>

            {dataNewFiltered?.map((item, index) => (
              <div key={index} className="producListItem">
                <p>{item.idUpsellItem}</p>
                <p className="productListDescription">{item.itemDescription}</p>
                <p className="productListPrice">{(item.precio).toFixed(2)} €</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
