import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../Shared/Context/AppContext";
import dataGet from "../../../Shared/API/dataGet";
const getTotalIncome = (data) => {
  const days = data.groupedCheckins.flatMap((item) => item.checkins);
  const groupedByStaffId = days.reduce((acc, current) => {
    const { staffId } = current;

    if (!acc[staffId]) {
      acc[staffId] = [];
    }

    acc[staffId].push(current);

    return acc;
  }, {});

  const resultArray = Object.keys(groupedByStaffId).map((staffId) => {
    return {
      staffData: groupedByStaffId[staffId],
    };
  });

  const returnArr = [];
  for (const staff of resultArray) {
    const staffName = staff.staffData[0].staffName;
    const checkins = staff.staffData.flatMap((item) => item.checkins);
    const roomUpselling = checkins.reduce((acc, item) => {
      return acc + item.nights * item.increment;
    }, 0);
    const objToPush = {
      staffName: staffName,
      roomUpselling: roomUpselling,
    };
    returnArr.push(objToPush);
  }
};
const FdDashboarView = ({
  setDataToShow,
  setActualHotelData,
  setLoading,
  dates,
}) => {
  const { hotelData, periodicity } = useAppContext();
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(true);
  const [objectiveType, setObjectiveType] = useState();

  useEffect(() => {
    if (hotelData && hotelData._id) {
      const idHotel = hotelData._id;
      const groupId = hotelData.idHotelGroup._id;
      const pdf = false;
      dataGet
        .getFDData(dates, idHotel, pdf, groupId)
        .then((response) => {
          setData(response);
          setActualHotelData(response);
        })
        .catch((error) => {
          console.error("error al obtener los datos:", error);
        });
    }
  }, [hotelData, dates]);

  useEffect(() => {
    let incomeTotal = 0;
    let exitData = false;

    if (data && data.data && periodicity) {
      if (parseInt(periodicity) === 1) {
        //diario
        if (data.data.periode.length > 0) {
          exitData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.periode);
      } else if (parseInt(periodicity) === 10) {
        if (data.data.MTD.length > 0) {
          exitData = true;
        }
        setObjectiveType("mensual");
        incomeTotal = getTotalIncome(data.data.MTD);
      } else if (parseInt(periodicity) === 100) {
        if (data.data.YTD) {
          exitData = true;
        }
        setObjectiveType("anual");
        incomeTotal = getTotalIncome(data.data.YTD);
      }
    }
  }, [data, periodicity]);
  return (
    <div className="dataZone">
      <h1>SOY EL DASBOARD DE FRONT DESK</h1>
    </div>
  );
};

export default FdDashboarView;
