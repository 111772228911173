import React from "react";
import "./App.css";

import Dashboard from "./Pages/Dashboard/Dashboard";
import { useAppContext } from "./Shared/Context/AppContext";
import LogIn from "./Pages/LogIn/LogIn";

function App() {
  const { hotelData, rol } = useAppContext();
  return <div className="App">
    {!rol && <LogIn />}
    {hotelData  && (rol === 'DIR' || rol === 'FOM' || rol==='GM' || rol === 'ADMIN')  && <Dashboard />}

    </div>;
}

export default App;
