import React, { useEffect, useState } from "react";
import "./RankingHotels.css";
import dataGet from "../../../Shared/API/dataGet";
import { useAppContext } from "../../../Shared/Context/AppContext";
import RankingHotelsSellPoints from "../../../Components/Graphics/RankingHotelsSellPoints";
import RankingHotelsGraph from "../../../Components/Graphics/RankingHotelesGraph";

const formatNumber = (number) => {
  const numberStr = Number(number).toFixed(2);
  const [numberWithoutDec, decNumber] = numberStr.split(".");
  const formattedInt = numberWithoutDec.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (decNumber !== "00") {
    return `${formattedInt},${decNumber}`;
  } else {
    return `${formattedInt}`;
  }
};
const RankingHotels = ({ hotelSelected, allHotelsData, dates }) => {
  const { periodicity } = useAppContext();
  const [data, setData] = useState(null);
  const [hotelData, setHotelData] = useState(null);
  const [hotelNames, setHotelNames] = useState(null);
  const [dataToShow, setDataToShow] = useState(null);

  const getHotelData = async (idHotel) => {
    const hotelData = await dataGet.hotelId(idHotel);
    return hotelData;
  };
  const getSellData = async (listHotels) => {
    const arrayResult = [];
    for (const hotel of listHotels) {
      const fbData = await dataGet.getFBData(dates, hotel.id, false, null);
      arrayResult.push({
        hotel: hotel.name,
        data: fbData,
      });
    }
    setData(arrayResult);
  };
  const getAllHotelsGroup = async (idGroup) => {
    const groupHotels = await dataGet.hotelGroupId(idGroup);
    return groupHotels;
  };
  useEffect(() => {
    if (hotelSelected !== "1") {
      getHotelData(hotelSelected).then((response) => {
        const idGroup = response.idHotelGroup._id;
        getAllHotelsGroup(idGroup).then((groupResponse) => {
          setHotelData(groupResponse);
        });
      });
    } else {
      setHotelData(allHotelsData);
    }
  }, [hotelSelected, allHotelsData]);

  useEffect(() => {
    if (hotelData && hotelData.length > 0) {
      const thisHotelNames = hotelData.map((item) => {
        return { name: item.hotelName, id: item._id };
      });
      setHotelNames(thisHotelNames);
    }
  }, [hotelData]);

  useEffect(() => {
    if (hotelNames && hotelNames.length > 0) {
      getSellData(hotelNames);
    }
  }, [hotelNames]);

  useEffect(() => {
    let periodicityValue = "";
    if (periodicity === "1") {
      periodicityValue = "periode";
    }
    if (periodicity === "5") {
      periodicityValue = "WTD";
    }
    if (periodicity === "10") {
      periodicityValue = "MTD";
    }
    if (periodicity === "100") {
      periodicityValue = "YTD";
    }

    if (data && data.length > 0) {
      const hotellActualSells = [];
      for (const hotel of data) {
        const hotelName = hotel.hotel;
        const sellHotelData = hotel.data.data[periodicityValue];
        for (const sellPoint of sellHotelData) {
          const pointOfSaleName = sellPoint.pointOfSaleName;
          const totalIncome = sellPoint.products.reduce((acc, item) => {
            return acc + item.totalPrice * item.totalUnits;
          }, 0);

          const objToPush = {
            hotel: hotelName,
            sellPoint: pointOfSaleName,
            totalIncome: totalIncome,
          };
          hotellActualSells.push(objToPush);
        }
      }
      const groupByHotel = Object.values(
        hotellActualSells.reduce((acc, { hotel, sellPoint, totalIncome }) => {
          // Si el hotel no existe en el acumulador, inicializa con las propiedades deseadas
          if (!acc[hotel]) {
            acc[hotel] = { hotel, sellPoints: [] };
          }

          // Añadir el sellPointName y totalIncome al array sellPoints del hotel
          acc[hotel].sellPoints.push({ sellPointName: sellPoint, totalIncome });

          return acc;
        }, {})
      );

      const finalResult = [];
      for (const hotel of groupByHotel) {
        const hotelName = hotel.hotel;
        const sellPointIncomes = hotel.sellPoints;
        const hotelTotalIncome = hotel.sellPoints.reduce((acc, item) => {
          return acc + item.totalIncome;
        }, 0);

        const finalObject = {
          hotelName: hotelName,
          sellPointIncomes: sellPointIncomes,
          totalHotelIncome: hotelTotalIncome,
        };
        finalResult.push(finalObject);
      }
      const sortedData = finalResult.sort(
        (a, b) => b.totalHotelIncome - a.totalHotelIncome
      );
      setDataToShow(sortedData);
    }
  }, [data, periodicity]);

  return (
    <div className="dataZone">
      <div className="rankingHotelsContainer">
        <div className="rankingHotelsHotel">
          <p className="rankingTodosLosHotelsTitle">Todos los hoteles</p>
          {dataToShow && dataToShow.length > 0 && (
            <RankingHotelsGraph data={dataToShow} />
          )}
        </div>
        {dataToShow &&
          dataToShow.length > 0 &&
          dataToShow.map((item, index) => (
            <div key={index} className="rankingHotelsHotel">
              <div className="rankinHotelsTitle">
                <p>{item.hotelName}</p>
                <p>{formatNumber(item.totalHotelIncome)}€</p>
              </div>

              <div>
                <div key={index} className="rankingHotelsGraph">
                  <RankingHotelsSellPoints
                    data={item.sellPointIncomes}
                    className="rankingHotelsGraph"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RankingHotels;
