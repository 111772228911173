import React, { useEffect, useState, useSyncExternalStore } from "react";
import "./DataZone.css";

import DashboardView from "../../Pages/Views/FB/DashboardView";
import { useAppContext } from "../../Shared/Context/AppContext";
import ProductsView from "../../Pages/Views/FB/ProductsView";
import SellPointsView from "../../Pages/Views/FB/SellPointsView";
import Loading from "../../Pages/Loading/Loading";
import NoData from "../../Pages/Views/FB/NoData";
import MyProfile from "../../Pages/Views/General/MyProfile";
import NoContracted from "../../Pages/Views/General/NoContracted";
import Reports from "../../Pages/Views/General/Reports";
import FdDashboarView from "../../Pages/Views/FD/FdDashboarView";
import ObjectivesView from "../../Pages/Views/General/ObjectivesView";
import RankingProductos from "../../Pages/Views/General/RankingProductos";
import RankingSellPoint from "../../Pages/Views/General/RankingSellPoint";
import NoService from "../../Pages/Views/General/NoService";
import RankingHotels from "../../Pages/Views/General/RankingHotels";
import NoTopNavbarSelected from "../../Pages/Views/General/NoTopNavbarSelected";

const DataZone = ({
  setDataToShow,
  dates,
  allHotelsData,
  hotelSelected,
  setActualHotelData,
  actualHotelData,
}) => {
  const {
    dataZoneView,
    selectedDepartment,
    hotelData,
    periodicity,
  } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [notContractedVisible, setNotContractedVisible] = useState(false);
  const [notTopNavbarSelected, setNotTopNavbarSelected] = useState(false);
  useEffect(() => {
    if (hotelData && hotelData.departments) {
      const hotelDepartments = hotelData.departments.map((item) => {
        switch (item.departmentName) {
          case "F&B":
            return "FB";
          case "FRONT DESCK":
            return "FD";
          case "SPA":
            return "SPA";
          default:
            return "error";
        }
      });
      if (!selectedDepartment || !hotelSelected || !periodicity) {
        setNotTopNavbarSelected(true);
      } else {
        setNotTopNavbarSelected(false);
        if (!hotelDepartments.includes(selectedDepartment)) {
          setNotContractedVisible(true);
        } else {
          setNotContractedVisible(false);
        }
      }
    }
  }, [hotelData, selectedDepartment, hotelSelected, periodicity]);
  return (
    <div className="dataZone">
      {!hotelData._id && !periodicity && <NoData />}
      {dataZoneView === "dashboard" &&
        selectedDepartment === "FB" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && (
          <DashboardView
            setActualHotelData={setActualHotelData}
            setLoading={setLoading}
            loading={loading}
            dates={dates}
            allHotelsData={allHotelsData}
            hotelSelected={hotelSelected}
          />
        )}
      {dataZoneView === "dashboard" &&
        selectedDepartment === "FD" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && (
          <FdDashboarView
            setDataToShow={setDataToShow}
            setActualHotelData={setActualHotelData}
            setLoading={setLoading}
            loading={loading}
            dates={dates}
          />
        )}
      {dataZoneView === "rankingProductos" &&
        selectedDepartment === "FB" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && (
          <RankingProductos
            hotelSelected={hotelSelected}
            setActualHotelData={setActualHotelData}
            setLoading={setLoading}
            loading={loading}
            dates={dates}
            allHotelsData={allHotelsData}
          />
        )}
      {dataZoneView === "rankingSellPoints" &&
        selectedDepartment === "FB" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && (
          <RankingSellPoint
            hotelSelected={hotelSelected}
            setActualHotelData={setActualHotelData}
            setLoading={setLoading}
            loading={loading}
            dates={dates}
            allHotelsData={allHotelsData}
          />
        )}
      {dataZoneView === "products" &&
        selectedDepartment === "FB" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && <ProductsView />}
      {dataZoneView === "sellPoints" &&
        selectedDepartment === "FB" &&
        hotelData._id &&
        !notContractedVisible &&
        periodicity !== null &&
        periodicity !== undefined && (
          <SellPointsView hotelSelected={hotelSelected} />
        )}
      {dataZoneView === "SETTINGS" && !notContractedVisible && <MyProfile hotelSelected={hotelSelected}/>}
      {dataZoneView === "reports" && (
        <Reports setLoading={setLoading} hotelSelected={hotelSelected} />
      )}
      {dataZoneView === "incentives" && <NoService type={"INCENTIVOS"} />}
      {dataZoneView === "rankingEmpleados" && <NoService type={"EMPLEADOS"} />}
      {dataZoneView === "hoteles" && (
        <RankingHotels
          hotelSelected={hotelSelected}
          dates={dates}
          allHotelsData={allHotelsData}
        />
      )}
      {dataZoneView === "historics" && <NoService type={"HISTÓRICOS"} />}
      {dataZoneView === "objectives" && <ObjectivesView />}
      {loading && <Loading />}
      {notTopNavbarSelected && <NoTopNavbarSelected />}
      {notContractedVisible && (
        <NoContracted setNotContractedVisible={setNotContractedVisible} />
      )}
    </div>
  );
};

export default DataZone;
