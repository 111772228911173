import React from 'react'

const NoPossibleAllHotels = () => {
    return (
        <div className="dataZone">
          <div className="noServiceContainer">
            <p>Visión no posible con</p>
            <p>la selección de hotel actual</p>
            <p>selecciona un único hotel</p>
          </div>
        </div>
      );
}

export default NoPossibleAllHotels
