import api from "./api";

const dataPut = {
  hotelContacts: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/hotel_contacts/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  groupContacts: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/group_contacts/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  hotelRoom: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/rooms/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  upsellingItem: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/hotel_rooms_upselling/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  crossellingItem: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/crosselling_items/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  hotel: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/hotel/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  dailyRegister: async (id, objToSave) => {
    return new Promise((resolve, reject) => {
      api
        .put("/daily_entry/edit/" + id, objToSave)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};


export default dataPut;
