import React from 'react'

const NoTopNavbarSelected = () => {
  return (
    <div className="dataZone">
      <div className="noServiceContainer">
        <p>Selecciona</p>
        <p>HOTEL, DEPARTAMENTO Y PERIODO</p>
        <p>a mostrar</p>
      </div>
    </div>
  )
}

export default NoTopNavbarSelected
