import React, { useEffect, useState, useSyncExternalStore } from "react";
import "./TopNavbar.css";
import logo from "../../Images/logoHbd.svg";
import { useAppContext } from "../../Shared/Context/AppContext";
import dataGet from "../../Shared/API/dataGet";
import api from "../../Shared/API/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendario from "../../Images/Icons/calendario.png";

function getYesterdayDateString() {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const formattedDate = today.toISOString().split("T")[0];
  return `${formattedDate},${formattedDate}`;
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const TopNavbar = ({
  setDataToShow,
  dataToShow,
  setDates,
  dates,
  setAllHotelsData,
  hotelSelected,
  setHotelSelected,
}) => {
  const {
    groupData,
    setGroupData,
    hotelData,
    setHotelData,
    setHotelLogo,
    hotelLogo,
    selectedDepartment,
    setSelectedDepartment,
    setPeriodicity,
    periodicity,
    setDataZoneView,
    onlyOneHotel,
    allGroups,
    datosDashboard,
    setDatosDashboard,
  } = useAppContext();

  useEffect(() => {
    const unicDate = dates.split(",");
    const dateParts = unicDate[0].split("-");
    const formatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    let fechaResultadoFormat = "";
    if (unicDate) {
      const unicDateDate = new Date(unicDate[0]);
      unicDateDate.setDate(unicDateDate.getDate() - 6);
      const fechaResultado = unicDateDate.toISOString().split("T")[0];
      const fechaResultadoParts = fechaResultado.split("-");
      fechaResultadoFormat = `${fechaResultadoParts[2]}-${fechaResultadoParts[1]}-${fechaResultadoParts[0]}`;
    }

    if (periodicity === "1") {
      setDataToShow(formatDate);
    } else if (periodicity === "5") {
      setDataToShow(`${fechaResultadoFormat} al ${formatDate}`);
    } else if (periodicity === "10") {
      const actualMonth = months[parseInt(unicDate[0].split("-")[1]) - 1];
      setDataToShow(`${actualMonth} de ${unicDate[0].split("-")[0]}`);
    } else if (periodicity === "100") {
      const actualYear = unicDate[0].split("-")[0];
      setDataToShow(actualYear);
    }
  }, [periodicity]);

  const [changeDatesVisible, setChangeDatesVisible] = useState(false);
  const [monthChangeDatesMonth, setMonthChangeDatesMonth] = useState(0);
  const [monthChangeDatesYear, setMonthChangeDatesYear] = useState(0);
  const [yearChangeDates, setYearChangeDates] = useState(0);
  const [startDate, setStartDate] = useState(new Date()); // Estado para la fecha seleccionada
  const [hotelToShow, setHotelToShow] = useState("");
  const [hotelsGroup, setHotelsGroup] = useState(null);
  const [selectValue, setSelectValue] = useState("inicio");
  const [groupSelectorVisible, setGroupSelectorVisible] = useState(false);
  const [groupNameList, setGroupNameList] = useState(null);

  useEffect(() => {
    if (allGroups) {
      const groupList = allGroups.map((item) => {
        return { id: item._id, groupName: item.name };
      });
      setGroupNameList(groupList);
      setGroupSelectorVisible(true);
    } else {
      setGroupSelectorVisible(false);
    }
  }, [allGroups]);
  useEffect(() => {
    const monthInt = parseInt(monthChangeDatesMonth);
    const yearInt = parseInt(monthChangeDatesYear);

    if (monthInt === 0 && yearInt === 0) {
      setDates(getYesterdayDateString());
    } else {
      const lastDayOfMonth = new Date(yearInt, monthInt, 0)
        .toISOString()
        .split("T")[0];
      const formattedDate = `${lastDayOfMonth},${lastDayOfMonth}`;
      setDates(formattedDate);
    }
  }, [monthChangeDatesMonth, monthChangeDatesYear]);

  useEffect(() => {
    const yearInt = parseInt(yearChangeDates);
    if (yearInt === 0) {
      setDates(getYesterdayDateString());
    } else {
      const formattedDate = `${yearInt}-12-31, ${yearInt}-12-31`;
      setDates(formattedDate);
    }
  }, [yearChangeDates]);
  useEffect(() => {
    const date = new Date(startDate).toISOString().split("T")[0];
    setDates(`${date},${date}`);
    const dateParts = date.split("-");
    const formatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    if (periodicity === "1") {
      setDataToShow(`${formatDate}`);
      setChangeDatesVisible(false)
    }
    if (periodicity === "5") {
      // Convierte la cadena de texto (yyyy-mm-dd) a un objeto Date
      const originalDate = new Date(date);

      // Restar 6 días (6 días en milisegundos)
      originalDate.setDate(originalDate.getDate() - 6);

      // Devuelve la fecha formateada de nuevo como yyyy-mm-dd
      const yyyy = originalDate.getFullYear();
      const mm = String(originalDate.getMonth() + 1).padStart(2, "0"); // Mes empieza desde 0
      const dd = String(originalDate.getDate()).padStart(2, "0");
      const sixDaysAgo = `${dd}-${mm}-${yyyy}`
      setDataToShow(`${sixDaysAgo} al ${formatDate}`)
      setChangeDatesVisible(false)
    }
  }, [startDate]);

  // useEffect(() => {
  //   console.log(groupData);
  //   setHotelSelected(groupData[0]._id);
  //   setSelectedDepartment("FB");
  //   setPeriodicity("1");
  // }, []);
  useEffect(() => {
    console.log(dates);
    if (hotelSelected) {
      if (hotelSelected.toString() === "0") {
      } else if (hotelSelected.toString() === "1") {
        setAllHotelsData(groupData);
        setHotelToShow(groupData[0].idHotelGroup.name);
      } else {
        setAllHotelsData(null);
        api.get("/hotel/id/" + hotelSelected).then((response) => {
          setHotelData(response);
          setHotelToShow(response.hotelName);
          dataGet
            .getFBData(dates, hotelSelected, false, response.idHotelGroup)
            .then((getResponse) => {
              let thisPeriodicityData = "";
              let thisPeriodicityInfo = "";
              let thisPeriodicityDataAnterior = "";
              let thisPeriodicityInfoAnterior = "";

              if (periodicity === "1") {
                let thisPeriodicityData = "periode";
                thisPeriodicityInfo = "periode";
                thisPeriodicityDataAnterior = "Yesterday";
                thisPeriodicityInfoAnterior = "yesterday";
              }
              if (periodicity === "5") {
                thisPeriodicityData = "WTD";
                thisPeriodicityInfo = "WTD";
                thisPeriodicityDataAnterior = "lastWeek";
                thisPeriodicityInfoAnterior = "lastWeek";
              }
              if (periodicity === "10") {
                thisPeriodicityData = "MTD";
                thisPeriodicityInfo = "MTD";
                thisPeriodicityDataAnterior = "lastMonth";
                thisPeriodicityInfoAnterior = "lastMonth";
              }
              if (periodicity === "100") {
                thisPeriodicityData = "YTD";
                thisPeriodicityInfo = "YTD";
                thisPeriodicityDataAnterior = "lastYear";
                thisPeriodicityInfoAnterior = "lastYear";
              }
              const objetData = {
                dailyInfoRequired: {
                  data: getResponse.dailyInfoRequired[thisPeriodicityInfo],
                  ant: getResponse.dailyInfoRequired[
                    thisPeriodicityInfoAnterior
                  ],
                },
                data: {
                  data: getResponse.data[thisPeriodicityData],
                  ant: getResponse.data[thisPeriodicityDataAnterior],
                },
                dates: getResponse.dates,
                objectives: getResponse.objectives,
                sellPoints: getResponse.sellPoints,
              };
              setDatosDashboard(getResponse);
            });
        });
      }
    }
  }, [hotelSelected, periodicity, dates]);

  useEffect(() => {
    if (onlyOneHotel) {
      setHotelData(onlyOneHotel);
      setHotelToShow(onlyOneHotel.hotelName);
      setHotelSelected(onlyOneHotel._id);
    }
  }, [onlyOneHotel]);

  useEffect(() => {
    const hotelId = hotelData.idHotelGroup?._id || hotelData.idHotelGroup;
    if (hotelId) {
      dataGet.getLogo(hotelId).then((logoResponse) => {
        setHotelLogo(logoResponse);
      });
    }
  }, [hotelData, setHotelLogo]);

  const fnChangeMyProfile = (value) => {
    if (value === "perfil") {
      setDataZoneView("SETTINGS");
      setSelectValue("inicio");
    }
  };

  const fnSelectGroup = (group) => {
    if (group && hotelData) {
      if (group !== "0") {
        const hotelFiltered = hotelData.filter(
          (item) => item.idHotelGroup._id === group
        );
        setGroupData(hotelFiltered);
      }
    }
  };
  return (
    <div className="topNavbar">
      <div className="hbdLogo">
        <img src={logo} alt="hbd logo" title="logo hbd" />
      </div>
      <div className="selectors_hotelName">
        <div className="selectorsTop">
          <div className="selectGroup_Hotel">
            {groupSelectorVisible && (
              <select
                onChange={(e) => fnSelectGroup(e.target.value)}
                className="departmentSelectorHotel"
              >
                <option value="0">GRUPO</option>
                {groupNameList &&
                  groupNameList.length > 0 &&
                  groupNameList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.groupName}
                    </option>
                  ))}
              </select>
            )}
            {!onlyOneHotel && (
              <select
                className="departmentSelectorHotel"
                onChange={(e) => {
                  setHotelSelected(e.target.value);
                  setDates(getYesterdayDateString());
                  setChangeDatesVisible(false);
                }}
              >
                <option value={0}>HOTEL</option>
                {groupData?.length > 0 &&
                  groupData.map((hotel, index) => (
                    <option key={index} value={hotel._id}>
                      {hotel.hotelName}
                    </option>
                  ))}
                {groupData && <option value={1}>Todos los hoteles</option>}
              </select>
            )}
          </div>
          <select
            value={selectedDepartment}
            onChange={(e) => {
              setSelectedDepartment(e.target.value);
              setDates(getYesterdayDateString());
              setChangeDatesVisible(false);
            }}
            className="departmentSelector"
          >
            <option value="">Departamentos</option>
            {hotelSelected && <option value="FD">Front desk</option>}
            {hotelSelected && <option value="FB">F & B</option>}
            {hotelSelected && <option value="SPA">SPA</option>}
          </select>
          <div>
            <select
              className="departmentSelector"
              onChange={(e) => {
                setPeriodicity(e.target.value);
                setDates(getYesterdayDateString());
                setChangeDatesVisible(false);
              }}
            >
              <option>PERIODICIDAD</option>
              {selectedDepartment && <option value={1}>DIARIO</option>}
              {selectedDepartment && <option value={5}>SEMANAL</option>}
              {selectedDepartment && <option value={10}>MENSUAL</option>}
              {selectedDepartment && <option value={100}>ANUAL</option>}
            </select>
          </div>
          <div>
            {(periodicity === '1' || periodicity === '5') && 
            <button
              className="btnChangeDates"
              title="modificar fechas"
              onClick={() => {
                setChangeDatesVisible(!changeDatesVisible);
              }}
            >
              <img src={calendario} alt="calendario" />
            </button>}

            {changeDatesVisible && periodicity === "10" && (
              <div className="dateSelectorsGroup">
                <select
                  onChange={(e) => setMonthChangeDatesMonth(e.target.value)}
                  className="dateSelectors"
                >
                  <option value={0}>mes</option>
                  <option value={1}>enero</option>
                  <option value={2}>febrero</option>
                  <option value={3}>marzo</option>
                  <option value={4}>abril</option>
                  <option value={5}>mayo</option>
                  <option value={6}>junio</option>
                  <option value={7}>julio</option>
                  <option value={8}>agosto</option>
                  <option value={9}>septiembre</option>
                  <option value={10}>octubre</option>
                  <option value={11}>noviembre</option>
                  <option value={12}>diciembre</option>
                </select>
                <select
                  onChange={(e) => setMonthChangeDatesYear(e.target.value)}
                  className="dateSelectors"
                >
                  <option value={0}>año</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                </select>
              </div>
            )}
            {changeDatesVisible && periodicity === "100" && (
              <div>
                <select onChange={(e) => setYearChangeDates(e.target.value)}>
                  <option value={0}>año</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                </select>
              </div>
            )}
            {changeDatesVisible && periodicity === "1" && (
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd" // Formato de la fecha
                  className="custom-datepicker" // Clase para estilos personalizados
                  popperPlacement="bottom" // Posición del calendario emergente
                  calendarClassName="custom-calendar" // Clase CSS para estilos del calendario
                />
              </div>
            )}
            {changeDatesVisible && periodicity === "5" && (
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd" // Formato de la fecha
                  className="custom-datepicker" // Clase para estilos personalizados
                  popperPlacement="bottom" // Posición del calendario emergente
                  calendarClassName="custom-calendar" // Clase CSS para estilos del calendario
                />
              </div>
            )}
          </div>
          <div className="setActualDate">
            {/* <p
          
          onClick={() =>{
            setChangeDatesVisible(false)
            setDates(getYesterdayDateString())}}>
            {periodicity === "1"
              ? "ir a: Ayer"
              : periodicity === "10"
              ? "ir a: Éste mes"
              : periodicity === "100"
              ? "ir a: Éste año"
              : null}
          </p> */}
          </div>
        </div>
        <div className="hotelTitle">
          <h2>
            Hola, {hotelToShow} ({dataToShow})
          </h2>
        </div>
      </div>

      <div className="logo_hotelName">
        <div className="hotelLogo">
          <img src={hotelLogo} alt="logo hotel" className="iconImg" />
        </div>
        <div className="settingsIcon">
          <select
            onChange={(e) => fnChangeMyProfile(e.target.value)}
            className="selectPerfil"
            value={selectValue}
          >
            <option value="inicio">mi perfil</option>
            <option value="perfil">configuración de perfil</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
